import React from "react";
import OfferImageSlider from "./slider/OfferImageSlider";
import { Grid } from "@mui/material";
import useHome from "./hooks/useHome";
import GridSpace from "../footer/components/GridSpace";
import Requirements from "./requirement/Requirements";
import TopVacationDestinations from "./../../assets/vacationDestinations/TopVacationDestinations";
import VacationDestinations from "./vacationDestination/VacationDestinations";
import Flights from "./flights/Flights";
import allAirlines from "../../assets/Airlines/allAirlines";
import ChooseUs from "./chooseUs/ChooseUs";
import useChooseUs from "./chooseUs/hooks/useChooseUs";
import OurServices from "./ourServices/OurServices";
import useServices from "./ourServices/hooks/useServices";
import CallTollFreeNo from "./callTollFree/CallTollFreeNo";

const Home = () => {
  const { requirements } = useHome();
  const { destinations } = TopVacationDestinations();
  const { airlines } = allAirlines();
  const { WhyChooseUs } = useChooseUs();
  const { services } = useServices();

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>
        
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <OfferImageSlider />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Requirements
            text={requirements.text}
            description={requirements.description}
          />
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <VacationDestinations
            title={"Top Vacation Destinations"}
            destinations={destinations}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Flights airlines={airlines} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ChooseUs chooseUs={WhyChooseUs} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <OurServices services={services} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
        
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
