import SpiritImage from "./../../assets/AirlinesBanner/Spirit.webp";
import SpiritLogo from "./../../assets/Airlines/USA Airlines/Spirit.webp";
import useSpiritItinerary from "./Itinerary/useSpiritItinerary";

import DeltaImage from "./../../assets/AirlinesBanner/Delta.webp";
import DeltaLogo from "./../../assets/Airlines/USA Airlines/Delta.webp";
import useDeltaItinerary from "./Itinerary/useDeltaItinerary";

import FrontierImage from "./../../assets/AirlinesBanner/Frontier.webp";
import FrontierLogo from "./../../assets/Airlines/USA Airlines/frontier-airlines.webp";
import useFrontierItinerary from "./Itinerary/useFrontierItinerary";

import SouthwestImage from "./../../assets/AirlinesBanner/Southwest.webp";
import SouthwestLogo from "./../../assets/Airlines/USA Airlines/Southwest-Airlines.webp";
import useSouthwestItinerary from "./Itinerary/useSouthwestItinerary";

import AmericanAirlinesImage from "./../../assets/AirlinesBanner/American.webp";
import AmericanAirlinesLogo from "./../../assets/Airlines/USA Airlines/American Airlines.webp";
import useAmericanAirlineItinerary from "./Itinerary/useAmericanAirlineItinerary";

import JetBlueImage from "./../../assets/AirlinesBanner/JetBlue.webp";
import JetBlueLogo from "./../../assets/Airlines/USA Airlines/JetBlue.webp";
import useJetBlueItinerary from "./Itinerary/useJetBlueItinerary";

const useAirlines = () => {
  return {
    airlines: [
      {
        route: "/spirit-airline",
        name: "Spirit Airline",
        banner: SpiritImage,
        price: "$59",
        logo: SpiritLogo,
        itineraryList: useSpiritItinerary().list,
      },
      {
        route: "/southwest-airline",
        name: "Southwest Airline",
        banner: SouthwestImage,
        price: "$79",
        logo: SouthwestLogo,
        itineraryList: useSouthwestItinerary().list,
      },
      {
        route: "/jetblue-airline",
        name: "JetBlue Airline",
        banner: JetBlueImage,
        price: "$59",
        logo: JetBlueLogo,
        itineraryList: useJetBlueItinerary().list,
      },
      {
        route: "/delta-airline",
        name: "Delta Airline",
        banner: DeltaImage,
        price: "$99",
        logo: DeltaLogo,
        itineraryList: useDeltaItinerary().list,
      },
      {
        route: "/frontier-airline",
        name: "Frontier Airline",
        banner: FrontierImage,
        price: "$59",
        logo: FrontierLogo,
        itineraryList: useFrontierItinerary().list,
      },
      {
        route: "/american-airline",
        name: "American Airline",
        banner: AmericanAirlinesImage,
        price: "$119",
        logo: AmericanAirlinesLogo,
        itineraryList: useAmericanAirlineItinerary().list,
      },
    ],
  };
};

export default useAirlines;
