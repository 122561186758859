const useSpiritItinerary = () => {
  return {
    list: [
      {
        origin: "Las Vegas",
        destination: "Chicago",
      },
      {
        origin: "Miami",
        destination: "New York",
      },
      {
        origin: "Orlando",
        destination: "Philadelphia",
      },
      {
        origin: "Orlando",
        destination: "Boston",
      },
      {
        origin: "New York",
        destination: "Atlanta",
      },
      {
        origin: "Hawaii",
        destination: "Los Angeles",
      },
      {
        origin: "New Orleans",
        destination: "Chicago",
      },
      {
        origin: "Las Vegas",
        destination: "Dallas",
      },
    ],
  };
};

export default useSpiritItinerary;
