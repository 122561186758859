import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid } from "@mui/material";
import RootRoutes from "./components/router/RootRoutes";

function App() {
  return (
    <Grid>
      <Router>
        <RootRoutes />
      </Router>
    </Grid>
  );
}

export default App;
