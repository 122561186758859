import { useMediaQuery, Box, Grid } from "@mui/material";
import React from "react";
import Title from "./Title";

const Flights = ({ airlines }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box style={{ width: "100%" }}>
      <Title />
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {airlines.map((airline, index) => (
              <div
                key={index}
                sx={
                  isSmallScreen
                    ? {
                        height: "3em",
                        width: "5%",
                        // padding: "1em 0.5em",
                        margin: ".3em",
                        // borderRadius: 6,
                        // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        // backgroundColor: "#2582c2",
                        // background: "linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)",
                        // background:
                        //   "linear-gradient(180deg, rgba(220,212,224,1) 37%, rgba(77,89,110,1) 100%)",
                        //   "linear-gradient(0.25turn, rgba(63, 135, 166, 0.5), rgba(235, 248, 225, 0.5), rgba(246, 157, 60, 0.5))",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {
                        height: "6em",
                        width: "15%",
                        padding: "1em 0.5em",
                        // margin: "1em",
                        margin: ".6em",
                        borderRadius: 10,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        // backgroundColor: "#2582c2",
                        // background: "linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)",
                        background:
                          "linear-gradient(180deg, rgba(220,212,224,1) 37%, rgba(77,89,110,1) 100%)",
                        //   "linear-gradient(0.25turn, rgba(63, 135, 166, 0.5), rgba(235, 248, 225, 0.5), rgba(246, 157, 60, 0.5))",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                <div
                  style={
                    {
                    width: "80%",
                    height: "auto",
                    objectFit: "cover",
                    padding: ".4em",
                    marginRight: "1em",
                  }}
                >
                  <img
                    width="200px"
                    src={airline.url}
                    alt={airline.title}
                    style={isSmallScreen ? {
                      width: "100px",
                    }: {}}
                  />
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default Flights;
