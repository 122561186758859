import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../home/Home";
import AboutUs from "../aboutUs/AboutUs";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import RefundPolicy from "../refundPolicy/refundPolicy";
import { ContactUsSection } from "../contactUs/contactUs";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";
import Flights from "../flights/Flights";
import Destinations from "../destinations/Destinations";
import PageComingSoon from "../pageComingSoon/PageComingSoon";

const MainRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/contact-us" exact element={<ContactUsSection />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          exact
          element={<TermsAndConditions />}
        />
        <Route path="/refund-policy" exact element={<RefundPolicy />} />
        <Route path="/flights" exact element={<Flights />} />
        <Route path="/destinations" exact element={<Destinations />} />
        <Route
          path="/packages"
          exact
          element={
            <PageComingSoon
              service={"Packages"}
              tollFreeNumber={"+1(888) 850-9883"}
            />
          }
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
