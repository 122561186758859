import React from "react";
import ServiceCard from "./ServiceCard";
import useServicesOffered from "./hooks/useServicesOffered";
import { Grid } from "@mui/material";

const ServicesOffered = () => {
   const {services} = useServicesOffered();
  return (
    <div
      style={{
        backgroundColor: "#2582c2",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "1.5em 0em",
      }}
    >
      <Grid container item xs={10} sm={10} md={8} lg={8} style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {services.map((service, index) => (
            <ServiceCard key={index}
            icon={service.icon}
            title={service.title}
            description={service.description}
          />
        ))}
      </Grid>
    </div>
  );
};

export default ServicesOffered;
