import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const CherryPointCard = ({ title, description }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <Grid item lg={6} md={6} sm={6} xs={10}>
      <Card
        sx={{
          width: "100%",
          height: isSmallScreen ? "245px" : "180px",
          backgroundColor: "transparent",
          padding: "1.5em 0em",
          border: "3px solid #ffcc00",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            color: "white",
            height: "100%",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#ffcc00",
              fontWeight: 600,
              width: "100%",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" style={{ textAlign: "center" }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CherryPointCard;
