import React, {useState} from "react";
import StyledTypography from "./styles/StyledTypography";
import StyledButton from "./styles/StyledButton";
import CallbackModal from "./CallbackModal";
// import { setSelectionRange } from "@testing-library/user-event/dist/utils";
// import Modal from '@mui/material/Modal';

const CallBackFromUs = ({ text, buttonText }) => {
  const [isCallBackFormOpen, setIsCallBackFormOpen] = useState(false);
  const handleCallUsClick = () => {
    window.open("tel:"+"+1(888) 850-9883");
  }
  const handleClick = () => {
    // const url = "https://forms.gle/7LJYD9QnPGZDi6uV6";
    // window.open(url, "_blank");
    setIsCallBackFormOpen(true);
  };
  return (
    <>
      <StyledButton text={"Call Us"} onClick={handleCallUsClick} />
      <StyledTypography text={text} />
      <StyledButton text={buttonText} onClick={handleClick} />
      {isCallBackFormOpen && <CallbackModal open={isCallBackFormOpen} handleClose={() => setIsCallBackFormOpen(false)} />} 
    </>
  );
};

export default CallBackFromUs;
