import { object, string } from "yup";

export const initialValues = () => {
  return {
    customer_name: "",
    email: "",
    phone: "",
    query: "",
  };
};

export const queryFormSchema = object({
  customer_name: string("Enter Name").required("Name is required"),
  email: string("Enter Email").email("Enter a valid email"),
  phone: string("Enter Phone")
    .matches(/^[0-9]+$/, "Phone must only contain numeric characters")
    .required("Phone is required"),
  query: string("Select an option").required("Please choose an option"),
});
