import React from "react";
import { Typography, useMediaQuery } from "@mui/material";

const Offer = ({text1, text2}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  return (
    <Typography
      variant={ isSmallScreen ? "body1" : isMediumScreen ? "h5" : "h2"}
      style={{
        width: "80%",
        textAlign: "center",
        fontWeight: 600,
        color: "white",
        filter: "brightness(100%)",
      }}
    >
        {`${text1}`}
        <br />
        {`${text2}`}
    </Typography>
  );
};

export default Offer;
