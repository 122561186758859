import React from 'react'
import useWhoAreWe from './hooks/useWhoAreWe';
import WhoAreWeCard from "./WhoAreWeCard";
import { Grid } from '@mui/material';

const WhoAreWe = () => {
    const {services} = useWhoAreWe();
  return (
    <div
      style={{
        backgroundColor: "#ffcc00",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "1.5em 0em",
      }}
    >
      <Grid container item xs={10} sm={10} md={8} lg={8} style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {services.map((service, index) => (
        <WhoAreWeCard
          key={index}
          title={service.title}
          description={service.description}
        />
      ))}
      </Grid>
    </div>
  )
}

export default WhoAreWe;