import TravelAgency from "./../../../../assets/Home/chooseUs/BestTravelAgency.svg";
import CompetitivePrice from "./../../../../assets/Home/chooseUs/CompetitivePrice.svg";
import GlobalCoverage from "./../../../../assets/Home/chooseUs/GlobalCoverage.svg";
import Secured from "./../../../../assets/Home/chooseUs/secure.svg";
import AllInOne from "./../../../../assets/Home/chooseUs/OneSolution.svg";
import EaseToPlanYourTravel from "./../../../../assets/Home/chooseUs/EaseToPlan.svg";
import MilitaryVeteran from "./../../../../assets/Home/chooseUs/deal.webp";
import TaxFree from "./../../../../assets/Home/chooseUs/tax-free.webp";

const useChooseUs = () => {
  return {
    WhyChooseUs: [
      {
        imageUrl: TravelAgency,
        title: "Exceptional Customer Service",
        description:
          "We are a team of professionals, experience to help"+
          " enhance your itinerary - or tailor-make you an "+
          "entire journey.",
      },
      {
        imageUrl: CompetitivePrice,
        title: "Good Quality Control with Competitive Price",
        description:
          "The price offered are affordable starting from the "+
          "ordinary to the exclusive.",
      },
      {
        imageUrl: GlobalCoverage,
        title: "Global Coverage",
        description:
          "There are many tourist attractions, hotels and "+
          "interesting entertainment.",
      },
      {
        imageUrl: Secured,
        title: "Secured",
        description:
          "We have payment gateway and we accept credit, debit, "+
          "UPI, or bank transfer.",
      },
      {
        imageUrl: AllInOne,
        title: "All In One Travel Solution",
        description:
          "We will help you to make a travel itinerary from scratch for your"+
          " trips from start to finish: cruise, land, family, group, adventure and beyond.",
      },
      {
        imageUrl: EaseToPlanYourTravel,
        title: "Easy To Plan Your Trip",
        description:
          "We will help you save money, time, less stress,  safety & security"+
          " and travel assistance or recommendation.",
      },
      {
        imageUrl: MilitaryVeteran,
        title: "Special Offers for Military & Veterans",
        description:
          "We offer exclusive travel deals and benefits for the military,"+
          " veterans and DOD civilians residing in US.",
      },
      {
        imageUrl: TaxFree,
        title: "No Hidden Taxes or Fees",
        description:
          "Our all tour itineraries include all the "+
          "applicable taxes. You pay only what we quote."+
          " No extras.",
      },
    ],
  };
};

export default useChooseUs;
