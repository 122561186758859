import {
  EmailRounded,
  FacebookRounded,
  LinkedIn,
  YouTube,
  WhatsApp,
  Instagram,
  //   Twitter,
} from "@mui/icons-material";
import AppIcon from "./../../assets/LogoTransparentBackground.png";

const useFooter = () => {
  const FacebookPageURL =
    "https://www.facebook.com/people/Welcome-Onboard-US/61557030097023";
  const InstagramPageURL = "https://www.instagram.com/welcome.onboard.us/";
  //   const TwitterPageURL = "";
  const Email = "info@welcomeonboard.co";
  const LinkedinProfileURL =
    "https://www.linkedin.com/company/welcome-onboard/about/";
  const YoutubeChannelURL = "https://www.youtube.com/@Welcomeonbaord";
  // const Whatsapp = "https://wa.me/+918587080065";

  return {
    disclaimer: {
      topic: "DISCLAIMER",
      description:
        "Welcomeonboard.co, a brand of Welcome Onboard LLC, is an " +
        "independent Travel service provider offering affordable " +
        "travel services. We wish to clarify that we are not affiliated" +
        " with any Airlines in any capacity. All branding displayed " +
        "on our platform is purely for demonstrative purposes and " +
        "does not indicate any association with any Airlines or organizations." +
        "Welcome Onboard LLC does not guarantee the accuracy of any " +
        "content, information, product, or service published on its " +
        "website. Please note that any such descriptions or " +
        "information, including but not limited to prices, " +
        "infographics, graphics, photos, product or service " +
        "descriptions, etc., may contain inaccuracies and errors, " +
        "for which Welcome Onboard LLC will not be held liable. " +
        "Additionally, Welcome Onboard LLC reserves the right to edit, " +
        "modify, or change any piece of information at any given time." +
        "In the event that a customer has made reservations from this " +
        "website at an incorrect published price, the customer will be " +
        "provided with the option to either proceed with the same " +
        "reservation at the correct price or to cancel the reservation " +
        "without incurring any termination fees." +
        "Welcome Onboard LLC is a complimentary service that aids " +
        "consumers in locating airline booking deals. All airlines " +
        "and travel agencies featured are independent entities, and " +
        "Welcome Onboard Travel Agency does not warrant or guarantee " +
        "any services provided by them. It is the responsibility of " +
        "the consumer to verify that the airline or travel agency they book" +
        " with holds the necessary licenses and insurance required for" +
        " the services being rendered. " +
        "Thank you for choosing Welcome Onboard LLC.",
    },
    callBackFromUs: {
      txt: "Get in-detail travel itinerary for your trip: ",
      btn: "Callback From Us",
    },
    company: {
      logo: AppIcon,
      tagLine: "TRIPS, HOTELS, FLIGHT, STAYS, PACKAGES",
    },
    companyCopyright: {
      copyrightSince:
        "Copyright © 2024 Welcome Onboard LLC. All rights reserved.",
      companyHeadquarters: "Headquarters Office: Flushing, New York.",
      fareOnWebsite: "*All fares above were found in last 8 hours. "+
      "Fares are round trip/one way. Fares incl. all fuel surcharges, "+
      "taxes & fees and our service fees. Displayed fares are based "+
      "on historical data, are subject to change and cannot be "+
      "guaranteed at the time of booking. See all " ,
      // `${<a href="/terms-and-conditions">Terms and conditions</a>}`,
    },
    socialMedias: [
      {
        to: Email,
        icon: <EmailRounded />,
        text: "Email",
      },
      {
        to: "/",
        // to: Whatsapp,
        icon: <WhatsApp />,
        text: "",
      },
      {
        to: InstagramPageURL,
        icon: <Instagram />,
        text: "",
      },
      {
        to: FacebookPageURL,
        icon: <FacebookRounded />,
        text: "",
      },
      //   {
      //     to: TwitterPageURL,
      //     icon: <Twitter />,
      //     text: "Twitter",
      //   },
      {
        to: LinkedinProfileURL,
        icon: <LinkedIn />,
        text: "",
      },
      {
        to: YoutubeChannelURL,
        icon: <YouTube />,
        text: "",
      },
    ],
    column1: [
      {
        to: "",
        text: "Statue of Liberty, New York",
      },
      {
        to: "",
        text: "Golden Gate Bridge, San Francisco",
      },
      {
        to: "",
        text: "Tunnel View, Yosemite National Park",
      },
      {
        to: "",
        text: "Hollywood, Los Angeles",
      },
      {
        to: "",
        text: "Space Needle, Seattle",
      },
      {
        to: "",
        text: "Willis Tower, Chicago",
      },
      {
        to: "",
        text: "Sea World, San Diego",
      },
    ],
    column2: [
      // {
      //   to: "",
      //   text: "Goa Tour Packages",
      // },
      // {
      //   to: "",
      //   text: "South India Tour Packages",
      // },
      // {
      //   to: "",
      //   text: "CharDham Yatra Packages",
      // },
      // {
      //   to: "",
      //   text: "Cordelia Cruise Packages",
      // },
      // {
      //   to: "",
      //   text: "Domestic flights with package",
      // },
      // {
      //   to: "",
      //   text: "Hotels booking",
      // },,

      {
        to: "/",
        text: "Home",
      },
      {
        to: "/privacy-policy",
        text: "Privacy Policy",
      },
      {
        to: "/refund-policy",
        text: "Refund Policy",
      },
      {
        to: "/about-us",
        text: "About Us",
      },
      {
        to: "/contact-us",
        text: "Contact Us",
      },
      {
        to: "/terms-and-conditions",
        text: "Terms and Conditions",
      },
      {
        to: "/flights",
        text: "Flights",
      },
    ],
    column3: [
      {
        to: "",
        text: "Forsyth Park, Georgia",
      },
      {
        to: "",
        text: "Kansas City Zoo, Missouri",
      },
      {
        to: "",
        text: "Crater Lake, Oregon",
      },
      {
        to: "",
        text: "Strip, Las Vegas",
      },
      {
        to: "",
        text: "Myrtle Beach, South Carolina",
      },
      {
        to: "",
        text: "Walt Disney World, Florida",
      },
      {
        to: "",
        text: "Freedom Trail, Boston",
      },
    ],
  };
};

export default useFooter;
