import React from 'react'

const FlightLogo = ({logo, text}) => {
  return (
    <div style={{
        textAlign: "center",
        margin: "2em 0em"
    }}>
    <img src={`${logo}`} alt={`${text}`} width={"50%"}/>
    </div>
  )
}

export default FlightLogo;