import React from "react";
import { useMediaQuery, Box, Typography, Grid } from "@mui/material";

const Title = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item lg={6} md={6} sm={8} xs={10}>
        {isSmallScreen ? (
          <Typography
            variant="h4"
            textAlign={"center"}
            fontWeight={600}
            fontFamily={"ITC Bauhaus font"}
            // fontFamily={"Lucida Handwriting, Cursive"}
          >
            Experience <br /> Never Before !!
          </Typography>
        ) : (
          <Typography
            variant="h2"
            textAlign={"center"}
            fontWeight={600}
            fontFamily={"ITC Bauhaus font"}
            // fontFamily={"Lucida Handwriting, Cursive"}
          >
            Experience Never Before !!
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Title;
