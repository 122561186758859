
const useWhoAreWe = () => {
  return {
    services: [
      {
        title: "Who Are We?",
        description:
          "We're here to be your trusted companion in travel!" +
          " With an array of irresistible deals and exclusive offers, we're" +
          " keeping them close and sharing only with our friends. So, are " +
          "you ready to join us on this journey?",
      },
      {
        title: "Globe-trotting Adventure!",
        description:
          "You are a wanderer and want to explore more" +
          " of this beautiful planet. Don't settle for some, see all" +
          " of them! Let us assist you in planning a great adventure, " +
          "multi-city, See it all trip. For queries related to round "+
          "the world trip call us!",
      },
      {
        title: "Why Us?",
        description:
          "As you're simply the best, it's " +
          "clear that your travel requirements demand nothing" +
          " less than perfection, and that's precisely what we" +
          " strive to provide. Your desire for excellence is " +
          "our priority—we're dedicated to delivering nothing " +
          "short of the best for you!",
      },
    ],
  };
};

export default useWhoAreWe;
