import React from "react";
import { Button } from "@mui/material";

const StyledButton = ({ text, onClick }) => {

  return (
    <Button
      variant="contained"
      size="large"
      style={{
        backgroundColor: "#454747",
        fontFamily: '"Georgia", serif',
        marginLeft: "2em",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default StyledButton;
