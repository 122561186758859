const useAmericanAirlineItinerary = () => {
  return {
    list: [
      {
        origin: "Portland",
        destination: "San Diego",
      },
      {
        origin: "Nashville",
        destination: "Austin",
      },
      {
        origin: "Charleston",
        destination: "Savannah",
      },
      {
        origin: "Sedona",
        destination: "Asheville",
      },
      {
        origin: "Denali National Park",
        destination: "Anchorage",
      },
      {
        origin: "Bar Harbor",
        destination: "Boston",
      },
      {
        origin: "Taos",
        destination: "Santa Fe",
      },
      {
        origin: "Martha's Vineyard",
        destination: "Newport",
      },
      {
        origin: "New Orleans",
        destination: "Savannah",
      },
      {
        origin: "Boise",
        destination: "Portland",
      },
    ],
  };
};

export default useAmericanAirlineItinerary;
