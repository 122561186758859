import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import useCheapFlights from "./useCheapFlights";
import GridSpace from "../../../footer/components/GridSpace";

const CheapFlights = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  const { cheapFlights } = useCheapFlights();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "70%",
        }}
      >
        <Typography
          variant={isSmallScreen ? "h4" : isMediumScreen ? "h5" : "h3"}
          fontWeight={600}
          fontFamily="ITC Bauhaus font"
        >
          {cheapFlights.topic}
        </Typography>
        <GridSpace />
        <Typography
          variant={isSmallScreen || isMediumScreen ? "body2" : "body1"}
          textAlign={"justify"}
          fontFamily="ITC Bauhaus font"
        >
          {cheapFlights.description.map((paragraph, index) => {
            return (
              <React.Fragment key={index}>
                {paragraph}
                <br /><br />
              </React.Fragment>
            );
          })}
        </Typography>
      </div>
    </div>
  );
};

export default CheapFlights;
