import { Grid } from "@mui/material";
import React, { useState } from "react";
import FaqQnA from "./FaqQnA";
import useFaqContents from "./hooks/useFaqContents";

const FaqContents = () => {
  const { faqs } = useFaqContents();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container item xs={12} sm={12} lg={8} md={8}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} sm={10} lg={10} md={10}>
          {faqs.map((qna, index) => (
            <FaqQnA
              key={index}
              qna={qna}
              panelIndex={index + 1}
              isExpanded={expanded}
              expanded={"panel" + (index + 1)}
              handleChange={handleChange("panel" + (index + 1))}
            />
          ))}
        </Grid>
      </div>
    </Grid>
  );
};

export default FaqContents;
