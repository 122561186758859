
const useCheapFlights = () => {
  return {
    cheapFlights: {
      topic: "Looking For Cheap Flight Tickets Online?",
      description: [
        "Welcome Onboard is your ultimate destination for securing" +
          " the best deals on flight ticket bookings, not just within the USA, " +
          "but across the globe. Our mission is simple: to save you both time and" +
          " money while offering exciting booking experiences. Say goodbye to the " +
          "hassle of scouring multiple websites in search of the cheapest plane ticket" +
          " prices. With WelcomeOnboard.co, you can secure the most affordable flight" +
          " tickets for both domestic and international travel.",

        "Whether you're planning a leisurely getaway or a crucial business trip, " +
          "WelcomeOnboard.co has you covered. Our online platform provides a seamless " +
          "booking experience, ensuring that you find the perfect air tickets at a " +
          "reasonable price. Whether you're in need of a budget-friendly one-way ticket " +
          "or an affordable round-trip option, we have a plethora of amazing travel " +
          "deals waiting for you.",

        "At WelcomeOnboard.co, we understand that planning travel can be overwhelming. " +
          "That's why our dedicated team works around the clock to provide you with " +
          "unparalleled assistance, all without any extra charges. Our commitment to " +
          "customer satisfaction drives us to go above and beyond to ensure that your " +
          "travel experience is smooth and stress-free.",

        "With Welcome Onboard, you can trust that you're getting the best value for " +
          "your money. Our extensive network of airline partners allows us to offer " +
          "competitive prices on flight tickets to destinations around the world. " +
          "Whether you're jetting off for a beach vacation, embarking on a business " +
          "trip, or visiting loved ones abroad, we have the perfect flight options to " +
          "suit your needs and budget.",

        "Booking with WelcomeOnboard.co is not just about securing great deals—it's " +
          "about enjoying peace of mind knowing that your travel plans are in good hands. " +
          "So why wait? Start planning your next adventure with us today and experience " +
          "the convenience and affordability of booking your flight tickets with Welcome " +
          "Onboard.",
      ],
    },
  };
};

export default useCheapFlights;
