const useSouthwestItinerary = () => {
    return {
      list: [
        {
          origin: "Seattle",
          destination: "San Francisco",
        },
        {
          origin: "Denver",
          destination: "Chicago",
        },
        {
          origin: "Houston",
          destination: "New Orleans",
        },
        {
          origin: "Portland",
          destination: "Los Angeles",
        },
        {
          origin: "Las Vegas",
          destination: "Grand Canyon",
        },
        {
          origin: "Miami",
          destination: "Key West",
        },
        {
          origin: "Washington D.C.",
          destination: "Philadelphia",
        },
        {
          origin: "Salt Lake City",
          destination: "Yellowstone National Park",
        },
      ]
    };
  };  

export default useSouthwestItinerary;