import { useState } from "react";
import QueryFormServiceApi from "../service/QueryFormServiceApi";

const useQueryForm = () => {
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const { postQuery,addQuery } = QueryFormServiceApi();

  const handleAddQuery = async (values, setAlert) => {
    const payload = {
      name: values.customer_name,
      email: values.email,
      phone: values.phone,
      query: values.query,
    };

    try {
      var r = await addQuery(payload);
      setResponse(r==null?"The Submission Failed":"We will contact you shortly");
      setAlert(true);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError(JSON.stringify(err.response.data.error));
      } else {
        throw err;
      }
    }
  };

  return {
    error: error,
    response: response,
    handleAddQuery: handleAddQuery,
  };
};

export default useQueryForm;
