import React from "react";
import { Stack } from "@mui/material";
import FooterColumn from "./footerColumn/FooterColumn";

const StackColumn = ({ data }) => {
  return (
    <Stack direction="column" spacing={1}>
      <FooterColumn dataList={data} />
    </Stack>
  );
};

export default StackColumn;
