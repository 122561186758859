import React from "react";
import { Typography, useMediaQuery } from "@mui/material";

const HeadingField = ({ text }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <>
      {isSmallScreen ? (
        <Typography variant="body2" fontWeight={600} textAlign={"center"}>
          {text}
        </Typography>
      ) : (
        <Typography variant="h5" fontWeight={600} textAlign={"center"}>
          {text}
        </Typography>
      )}
    </>
  );
};

export default HeadingField;
