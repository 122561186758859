import React from "react";
import { Box } from "@mui/material";
import styles from "./styles/CopyrightStyles";

const Copyright = ({
  companyCopyright,
  companyHeadquarters,
  fareOnWebsite,
}) => {
  const classes = styles();
  return (
    <>
      <Box className={classes.copyright}>
        {companyCopyright}
        <br />
        {companyHeadquarters}
        <br />
        <br />
        <div style={{ width: "70%", textAlign: "center" }}>
          {fareOnWebsite}
          <a
            href="/terms-and-conditions"
            style={{
              color: "white",
            }}
          >
            Terms and conditions
          </a>
        </div>
      </Box>
    </>
  );
};

export default Copyright;
