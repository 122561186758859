import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";

const PageComingSoon = ({ service, tollFreeNumber }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Typography
            variant={isSmallScreen ? "h4" : "h1"}
            fontWeight={600}
            textAlign={"center"}
          >
            Coming Soon....
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={500}
              textAlign={"center"}
              width={"70%"}
            >
              Our developers are working on creating this page. In the time
              being, CALL US on our TOLL FREE NUMBER ({tollFreeNumber}) for
              queries regarding {service}.
            </Typography>
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default PageComingSoon;
