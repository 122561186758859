import React from "react";
import styles from "./styles/ClickableLinksStyle";
import { Link } from "react-router-dom";
import NavbarButton from "../../../formik/navbarButton/NavbarButton";

const ClickableLinks = ({ pages }) => {
  const classes = styles();
  return (
    <div className={classes.toolbarDiv}>
      {pages.map((page, index) => (
        // <Link key={index} to={page.address}>
        <NavbarButton key={index} btnText={page.name} to={page.address} />
        // </Link>
      ))}
    </div>
  );
};

export default ClickableLinks;
