import HiddenIcon from "./../../../../../assets/AirlinePages/hide.png";
import DealIcon from "./../../../../../assets/AirlinePages/deal.png";
import CancellationIcon from "./../../../../../assets/AirlinePages/cancelled.png";
import RoundTheClockIcon from "./../../../../../assets/AirlinePages/back-in-time.png";
import PaymentIcon from "./../../../../../assets/AirlinePages/cashless-payment.png";

const useServicesOffered = () => {
  return {
    services: [
      {
        icon: DealIcon,
        title: "Best Deals Always",
        description: "Get exciting discounts upto 30% on each booking.",
      },
      {
        icon: HiddenIcon,
        title: "No Hidden Cost",
        description: "Book your flights' ticket with no surprise billing.",
      },
      {
        icon: CancellationIcon,
        title: "Free Cancellation",
        description: "Cancel your flight's tickets within 24 hours of booking.",
      },
      {
        icon: RoundTheClockIcon,
        title: "Round The Clock Assistance",
        description: "Get a 24x7 assistance on all your booking.",
      },
      {
        icon: PaymentIcon,
        title: "Multiple Payment Option",
        description: "We accept various payment mode for hassle-free bookings.",
      },
    ],
  };
};

export default useServicesOffered;
