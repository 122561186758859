import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  appImage: {
    width: "100%"
  },
  items:{
    paddingBottom: "1.5%",
    width: "100%",
  }
}));
