import React from "react";

const GridSpace = () => {
  return (
    <div
      style={{
        minHeight: "2em",
      }}
    ></div>
  );
};

export default GridSpace;
