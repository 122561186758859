import { Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import CallRoundedIcon from "@mui/icons-material/CallRounded";

const CallTollFreeNo = ({bg, bgBtn}) => {
    const isMediumScreen = useMediaQuery("(max-width:1000px)");
    const handleButtonClick = () => {
        window.open("tel:"+ "+1(888) 850-9883");
    }
  return (
    <div
      style={isMediumScreen ? {
        width: "100%",
        backgroundColor: `${bg}`,
        color: `${bgBtn}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "2em 0em",
      }:{
        width: "100%",
        backgroundColor: `${bg}`,
        color: `${bgBtn}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "2em 0em",
      }}
    >
      <Typography variant="h4" fontWeight={600} textAlign="center" >
        Call us on our Toll Free Number
      </Typography>

      <Button
        style={{
          backgroundColor: `${bgBtn}`,
          color: `${bg}`,
          // marginTop: "1em",
          fontFamily: "ITC Bauhaus font",
          // fontFamily: "Roboto",
          fontWeight: 600,
          fontSize: "28px",
          textTransform: "none",
          padding: "10px 25px 10px 25px",
          borderRadius: "10px",
        }}

        onClick={handleButtonClick}
      >
        <CallRoundedIcon />
        {"+1(888) 850-9883"}
      </Button>
    </div>
  );
};

export default CallTollFreeNo;
