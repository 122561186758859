const useSpiritItinerary = () => {
  return {
    list: [
        {
            origin: "Chicago",
            destination: "Las Vegas",
        },
        {
            origin: "New York",
            destination: "Miami",
        },
        {
            origin: "Philadelphia",
            destination: "Orlando",
        },
        {
            origin: "Boston",
            destination: "Orlando",
        },
        {
            origin: "Atlanta",
            destination: "New York",
        },
        {
            origin: "Los Angeles",
            destination: "Hawaii",
        },
        {
            origin: "Chicago",
            destination: "New Orleans",
        },
        {
            origin: "Dallas",
            destination: "Las Vegas",
        },
    ]
  }
}

export default useSpiritItinerary;