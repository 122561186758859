import { useMediaQuery } from "@mui/material";
import React from "react";
import TagLine from "./TagLine";
import TagLineDesc from "./TagLineDesc";

const Heading = ({ text, description }) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isXSmallScreen = useMediaQuery("(max-width:425px)");
  const isMediumScreen = useMediaQuery("(max-width:1024px)");
  return isXSmallScreen ? (
    <>
      <TagLine variant={"h4"} text={text} fontWeight={600} />
      <TagLineDesc variant={"body2"} text={description} fontWeight={700} />
    </>
  ) : isSmallScreen ? (
    <>
      <TagLine variant={"h3"} text={text} fontWeight={600} />
      <TagLineDesc variant={"h6"} text={description} fontWeight={700} />
    </>
  ) : isMediumScreen ? (
    <>
      <TagLine variant={"h2"} text={text} fontWeight={650} />
      <TagLineDesc variant={"h5"} text={description} fontWeight={700} />
    </>
  ) : (
    <>
      <TagLine variant={"h1"} text={text} fontWeight={650} />
      <TagLineDesc variant={"h4"} text={description} fontWeight={600} />
    </>
  );
};

export default Heading;
