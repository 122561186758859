import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import Menu from "../menuIcon/MenuIcon";
import styles from "./styles/AppBarStyles";
import ClickableLinks from "../clickableLinks/ClickableLinks";
import CompanyLogo from "../companyLogo/CompanyLogo";

const NavbarAppBar = ({isSmallScreen, isMediumScreen, toggleMenu, pages, AppIcon}) => {
    const classes = styles();
  return (
    <AppBar
      style={{
        position: "fixed",
        background: "black",
        boxShadow: "none",
        width: "100%",
        maxHeight: "10%",
        opacity: "1",
        paddingg: "2em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Toolbar
        style={{
          width: "80%",
        }}
      >
        <div className={classes.toolbarDiv}>
          {isSmallScreen && <Menu toggleMenu={toggleMenu} />}
          <CompanyLogo
            AppIcon={AppIcon}
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
          />
          <div>{!isSmallScreen && <ClickableLinks pages={pages} />}</div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarAppBar;
