import { Typography } from "@mui/material";
import React from "react";

const Title = ({title}) => {
  return (
    <Typography variant="h2" fontWeight={600} textAlign={"center"}>
      {title}
    </Typography>
  );
};

export default Title;
