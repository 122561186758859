import React from "react";
import { Link } from "react-router-dom";
import { ListItemText, ListItemButton } from "@mui/material";

const NavbarDrawerListItem = ({ page, toggleMenu }) => {
  return (
    <ListItemButton component={Link} to={page.address} onClick={toggleMenu}>
      <ListItemText primary={page.name} />
    </ListItemButton>
  );
};

export default NavbarDrawerListItem;
