import React from "react";
import { Route, Routes } from "react-router-dom";
import Website from "../website/Website";
import AirlinePages from "../airlinesPages/AirlinePages";

const RootRoutes = () => {
  return (
    <Routes>
      <Route path="/*" exact element={<Website />} />
      <Route path="/airlines/*" exact element={<AirlinePages />} />
    </Routes>
  );
};

export default RootRoutes;
