
const useHome = () => {
  return {
    TagLine: "Where every Journey Counts!",
    TagLineDescription: "Your Passport to Unforgettable Adventures & Endless Journeys",
    requirements: {
      text: "Send us your Requirements",
      description: "We are glad to help you out!",
      // description: "And Relax after packing your bags for the new adventure!!",
    }
  }
}

export default useHome;