import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import CallNowButton from "../airlinesPages/components/itinerary/CallNowButton";

const DestinationCard = ({ destination, handleCallButton, textColor, divBgColor }) => {
  return (
    <Grid item lg={4} md={5} sm={11} xs={11}>
      <Card
        sx={{
        //   padding: "1em",
          margin: "1em",
          height: "580px",
          position: "relative",
          backgroundColor: `${divBgColor}`,
        }}
      >
        <CardMedia
          component="img"
          height="350"
          image={destination.url}
          alt={destination.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            fontWeight={600}
            component="div"
            textAlign={"center"}
            color={textColor}
          >
            {destination.title}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> */}
        </CardContent>
        <div style={{
            position: "absolute",
            bottom: "1.2em",
            width: "95%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        }}>
        <CallNowButton text="Call Now to Book" handleCallButton={handleCallButton} />
        </div>
      </Card>
    </Grid>
  );
};

export default DestinationCard;
