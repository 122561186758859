import { Container, Box } from "@mui/material";
import React from "react";
import Title from "./title/Title";
import VacationSlider from "./slider/VacationSlider";

const VacationDestinations = ({ title, destinations }) => {
  return (
    <Box
      style={{ width: "100%", backgroundColor: " #ffcc00", padding: "1em 0" }}
    >
      <Title title={title} />
      <Container style={{ maxWidth: "95%" }}>
        <VacationSlider destinations={destinations} />
      </Container>
    </Box>
  );
};

export default VacationDestinations;
