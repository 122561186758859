// import indianTopVacationDestinations from "./indianTopVacationDestinations";
import usaTopVacationDestinations from "./usaTopVacationDestinations";

const TopVacationDestinations = () => {
  // const { destinations: indianDestinations } = indianTopVacationDestinations();
  const { destinations: usaDestinations } = usaTopVacationDestinations();
  return {
    destinations: [...usaDestinations],
    // destinations: [...indianDestinations, ...usaDestinations],
  };
};

export default TopVacationDestinations;
