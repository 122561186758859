import {
  useMediaQuery,
  Box,
  Grid,
  Card,
  CardMedia,
  Typography,
  CardContent,
} from "@mui/material";
import React from "react";
import Title from "./Title";

const OurServices = ({ services }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1024px)");

  return (
    <Box style={{ width: "100%" }}>
      <Title />
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div
            style={
              isMediumScreen
                ? {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "3em",
                  }
                : {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "3em",
                  }
            }
          >
            {services.map((service, index) => (
              <Card
                key={index}
                sx={
                  isSmallScreen
                    ? {
                        height: "26em",
                        width: "85%",
                        padding: "1em 0.5em",
                        margin: ".6em",
                        borderRadius: 6,
                        borderTopRightRadius: 88,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#2582c2",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: "#ffcc00",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                      }
                    : isMediumScreen
                    ? {
                        height: "30em",
                        width: "40%",
                        padding: "1em 0.5em",
                        margin: ".6em",
                        borderRadius: 6,
                        borderTopRightRadius: 68,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#2582c2",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: "#ffcc00",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                      }
                    : {
                        height: "35em",
                        width: "30%",
                        padding: "1em 0.5em",
                        borderRadius: 10,
                        borderTopRightRadius: 138,
                        boxShadow: "8px 8px 20px 4px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#2582c2",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: "#ffcc00",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                      }
                }
              >
                <CardContent
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {isSmallScreen ? (
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontWeight={600}
                      textTransform={"uppercase"}
                      color={"#ffcc00"}
                    >
                      {service.title}
                    </Typography>
                  ) : isMediumScreen ? (
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontWeight={600}
                      textTransform={"uppercase"}
                      color={"#ffcc00"}
                    >
                      {service.title}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      fontWeight={700}
                      textTransform={"uppercase"}
                      color={"#ffcc00"}
                    >
                      {service.title}
                    </Typography>
                  )}

                  <CardMedia
                    component={"img"}
                    sx={{
                      width: "80%",
                      height: "60%",
                      objectFit: "cover",
                      borderRadius: 10,
                      borderColor: "#ffcc00",
                      borderWidth: "0.5em",
                      borderStyle: "solid",
                    }}
                    image={service.imageUrl}
                    alt={service.title}
                  />
                  {isSmallScreen ? (
                    <Typography
                      variant="h6"
                      textAlign={"center"}
                      fontWeight={600}
                      fontFamily={"ITC Bauhaus font"}
                      // fontFamily={"Arial Black, Arial, sans-serif"}
                    >
                      {service.descriptionPart1}
                    </Typography>
                  ) : isMediumScreen ? (
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontWeight={600}
                      fontFamily={"ITC Bauhaus font"}
                      // fontFamily={"Arial Black, Arial, sans-serif"}
                    >
                      {service.descriptionPart1}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      fontWeight={600}
                      fontFamily={"ITC Bauhaus font"}
                      // fontFamily={"Arial Black, Arial, sans-serif"}
                    >
                      {service.descriptionPart1}
                    </Typography>
                  )}

                  {isSmallScreen ? (
                    <Typography
                      variant="subtitle1"
                      textAlign={"center"}
                      fontWeight={600}
                      fontFamily={"ITC Bauhaus font"}
                      // fontFamily={"Lucida Handwriting, Cursive"}
                    >
                      {service.descriptionPart2}
                    </Typography>
                  ) : isMediumScreen ? (
                    <Typography
                      variant="subtitle1"
                      textAlign={"center"}
                      fontWeight={600}
                      // fontFamily={"Lucida Handwriting, Cursive"}
                      fontFamily={"ITC Bauhaus font"}
                    >
                      {service.descriptionPart2}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontWeight={400}
                      fontFamily={"ITC Bauhaus font"}
                      // fontFamily={"Lucida Handwriting, Cursive"}
                    >
                      {service.descriptionPart2}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default OurServices;
