const useFrontierItinerary = () => {
  return {
    list: [
      {
        origin: "San Diego",
        destination: "Portland",
      },
      {
        origin: "Austin",
        destination: "Nashville",
      },
      {
        origin: "Savannah",
        destination: "Charleston",
      },
      {
        origin: "Asheville",
        destination: "Sedona",
      },
      {
        origin: "Anchorage",
        destination: "Denali National Park",
      },
      {
        origin: "Boston",
        destination: "Bar Harbor",
      },
      {
        origin: "Santa Fe",
        destination: "Taos",
      },
      {
        origin: "Newport",
        destination: "Martha's Vineyard",
      },
      {
        origin: "Savannah",
        destination: "New Orleans",
      },
      {
        origin: "Portland",
        destination: "Boise",
      },
    ]
  };
}

export default useFrontierItinerary;