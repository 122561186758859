import React from "react";
import Heading from "./heading/Heading";
import Box from "@mui/material/Box";
import { Card, CardMedia, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import QueryForm from "./queryForm/QueryForm";
import PlanItinerary from "./../../../assets/Home/plan itinerary.png";

const Requirements = ({ text, description }) => {
  const isXSmallScreen = useMediaQuery("(max-width:425px)");
  return (
    <Card
      sx={{
        background:
          "linear-gradient(180deg, rgba(237,234,219,10) 0%, rgba(149,216,219,.6) 100%)",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 16
      }}
    >
      <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <div style={{ position: "relative", height: "100%" }}>
            <CardMedia
              component="img"
              sx={{ width: "100%", height: "100%" }}
              image={PlanItinerary}
              alt="PlanItinerary"
            />
            <div
              style={
                !isXSmallScreen
                  ? {
                      width: "90%",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      paddingRight: "1em",
                      height: "100%",
                      overflow: "hidden",
                      transform: "translateY(35%)",
                    }
                  : {
                      width: "90%",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      paddingRight: "1em",
                      height: "100%",
                      overflow: "hidden",
                    }
              }
            >
              <div>
                <Heading
                  // description={"We are glad to help you out!"}
                  // text={"Send us your Requirements"}
                  text={text} description={description}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QueryForm />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Requirements;
