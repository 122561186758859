import React from 'react'
import LinkButton from '../../../formik/formikLinkButton/LinkButton';

const FooterColumn = ({dataList}) => {
    
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
        }}>
          {dataList.map((data, index) => (
            <LinkButton
              key={index}
              url={data.to}
              text={data.text}
            />
          ))}
        </div>
    )
}

export default FooterColumn;