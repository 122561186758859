import ApiService from '../../../../service/ApiService';
import { collection, addDoc,serverTimestamp } from "firebase/firestore";
import { firebase_db } from '../../../../service/firebase_service';
const QueryFormServiceApi = () => {
    const {postPublic} = ApiService();
    const getData = async () => {
      const res = await fetch("https://api.ipify.org/?format=json").then(response=>response.json());
      return res.ip;
    };
  return {
    postQuery: async (payload) => {
        const path = "query";
        const response = await postPublic(`${path}`, payload);
        return response.data;
    },
    addQuery: async (payload)=>{
      try {
        var ip_c = await getData();
        const docRef = await addDoc(collection(firebase_db, "query"), {
          name: payload.name, 
          phone: payload.phone,
          email: payload.email,
          query: payload.query,
          site: "co",
          created_at: serverTimestamp(),
          ip: ip_c
        });
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
      } catch (e) {
        console.error("Error adding document: ", e);
        return null;
      }
    },
    contactUS: async (payload)=>{
      try {
        var ip_c = await getData();
        const docRef = await addDoc(collection(firebase_db, "contact_us"), {
          name: payload.name,
          email: payload.email,
          message: payload.message,
          created_at: serverTimestamp(),
          site: "co",
          ip: ip_c
        });
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
      } catch (e) {
        console.error("Error adding document: ", e);
        return null;
      }
    }
  }
}

export default QueryFormServiceApi;