// import Ayodhya from "./backgroundImages/IndianBanners/ayodhya.webp";
// import Dubai from "./backgroundImages/IndianBanners/Dubai.webp";
// import Main from "./backgroundImages/IndianBanners/Main.webp";
// import World from "./backgroundImages/IndianBanners/world.webp";
// import Goa from "./backgroundImages/IndianBanners/goa.webp";
import Delta from "./backgroundImages/UsaBanners/Delta.webp";
import Frontier from "./backgroundImages/UsaBanners/Frontier.webp";
import Southwest from "./backgroundImages/UsaBanners/Southwest.webp";
import Spirit from "./backgroundImages/UsaBanners/Spirit.webp";
import JetBlue from "./backgroundImages/UsaBanners/JetBlue.webp";
import American from "./backgroundImages/UsaBanners/American.webp";


const bgImages = () => {
  // const indianBanners = () => {
  //   return [
  //     Main, Ayodhya, Dubai, Goa, World,
  //   ]
  // }

  const usaBanners = () => {
    return {
      banners: [
        Delta, Frontier, Southwest, Spirit, JetBlue, American,
    ]};
  }
  const {banners} = usaBanners();
  return {
    images: [...banners],
  };
};

export default bgImages;
