import React, { useRef } from "react";
import "./slick.css";
import "./slick-theme.css";
import Slider from "react-slick";
import VacationSliderContent from "./VacationSliderContent";
import { useMediaQuery } from "@mui/material";
import PrevSliderButton from "./PrevSliderButton";
import NextSliderButton from "./NextSliderButton";
import useSliderSettings from "./hooks/sliderSettings";

const VacationSlider = ({ destinations }) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const arrowRef = useRef(null);
  const { settings } = useSliderSettings();

  const goToPrevSlide = () => {
    if (arrowRef && arrowRef.current) {
      arrowRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (arrowRef && arrowRef.current) {
      arrowRef.current.slickNext();
    }
  };

  const gridSlider = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1em",
          }}
        >
          <PrevSliderButton fn={goToPrevSlide} />
          <div
            style={
              isSmallScreen
                ? {
                    width: "80%",
                  }
                : {
                    width: "80%",
                  }
            }
          >
            <Slider ref={arrowRef} {...settings}>
              {destinations.map((destination, index) => (
                <VacationSliderContent key={index} destination={destination} />
              ))}
            </Slider>
          </div>
          <NextSliderButton fn={goToNextSlide} />
        </div>
      </>
    );
  };

  return <>{gridSlider()}</>;
};

export default VacationSlider;
