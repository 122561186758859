import React from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useMediaQuery } from "@mui/material";
import SliderButton from "./SliderButton";

const NextSliderButton = ({ fn }) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  return (
    <>
      {isSmallScreen ? (
        <SliderButton
          icon={<ArrowForwardIosRoundedIcon fontSize="small" />}
          onClickFn={fn}
        />
      ) : (
        <SliderButton
          icon={<ArrowForwardIosRoundedIcon fontSize="large" />}
          onClickFn={fn}
        />
      )}
    </>
  );
};

export default NextSliderButton;
