import React from "react";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import { Button } from "@mui/material";

const CallNowButton = ({text, handleCallButton}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "0.5em 0em",
      }}
    >
      <Button
        style={{
          color: "white",
          backgroundColor: "blue",
          width: "60%",
          fontWeight: 600,
          fontSize: "1em",
          textTransform: "none",
          borderRadius: "10px",
          fontFamily: "ITC Bauhaus font",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
        onClick={handleCallButton}
      >
        <CallRoundedIcon />
        {text}
      </Button>
    </div>
  );
};

export default CallNowButton;
