import { Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import Offer from "./Offer";

const FlightImage = ({ imgSrc, imgText, price }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  const handleCallButton = () => {
    window.open("tel:" + "+1(888) 850-9883");
  };
  return (
    <div
      style={{
        width: "100%",
        height: isSmallScreen ? "45vh" : isMediumScreen ? "48vh" : "50vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <img
        src={`${imgSrc}`}
        alt={`${imgText}`}
        style={{
          filter: "brightness(50%)",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "80%",
          left: "0",
          top: "0",
          margin: "3em 0em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Offer
          text1={`Book Flight Tickets on Call for ${imgText}`}
          text2={`Starting @ ${price}`}
        />

        <Button
          style={{
            color: "white",
            backgroundColor: "blue",
            width: isSmallScreen ? "70%" : isMediumScreen ? "50%" : "40%",
            fontWeight: 600,
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "23px" : "23px",
            textTransform: "none",
            padding: "10px 25px 10px 25px",
            borderRadius: "10px",
            fontFamily: "ITC Bauhaus font",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
          onClick={handleCallButton}
        >
          <CallRoundedIcon />
          {isSmallScreen ? (
            <>
              {"Call Now"} <br /> {"+1(888) 850-9883"}
            </>
          ) : (
            <>{"Call Now +1(888) 850-9883"}</>
          )}
        </Button>
        <Typography
          variant={isSmallScreen ? "body2" : isMediumScreen ? "h6" : "h5"}
          style={{
            fontWeight: 600,
            color: "white",
            filter: "brightness(100%)",
          }}
        >
          <ul>
            <li>New Bookings At Unpublished Fares</li>
            <li>Booking Modifications</li>
            <li>Booking Cancellation</li>
          </ul>
        </Typography>
      </div>
    </div>
  );
};

export default FlightImage;
