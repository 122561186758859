import React from 'react'
import { Typography } from '@mui/material';

const TagLineDesc = ({variant, text, fontWeight}) => {
  return (
    <Typography
      variant={variant}
      style={{ 
        fontWeight: `${fontWeight}`, 
        fontFamily: "ITC Bauhaus font",
        // fontFamily: '"Lucida Handwriting", cursive',
        textAlign: "right",
       }}
    >
      {text}
    </Typography>
  )
}

export default TagLineDesc;