import React from "react";
import { Grid, Typography } from "@mui/material";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "./../home/callTollFree/CallTollFreeNo";
import useRefundPolicy from "./useRefundPolicy";

const RefundPolicy = () => {
  const {policy} = useRefundPolicy();
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div>
            <Typography variant="h1" fontWeight={600} textAlign={"center"}>
              Refund Policy
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "70%" }}>
                <Typography variant="body1" textAlign={"justify"}>
                  <ul>
                  {
                    policy.map((pol, index) => (
                      <li key={index}>
                        {pol}
                      </li>
                    ))
                  }
                  </ul>
                </Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
        
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default RefundPolicy;
