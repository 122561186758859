import { Box, Grid, Container } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import React from "react";
import MainRoutes from "../router/MainRoutes";
const Main = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");
  return (
    <Box
      sx={
        isSmallScreen || isMediumScreen
          ? {
              marginTop: "15%",
              minWidth: "100%",
              maxWidth: "100%",
            }
          : {
              marginTop: "6%",
              minWidth: "100%",
              maxWidth: "100%",
            }
      }
    >
      <Container maxWidth="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <MainRoutes />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Main;
