import React from "react";
import { Grid, Typography } from "@mui/material";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "./../home/callTollFree/CallTollFreeNo";

const PrivacyPolicy = () => {
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div>
            <Typography variant="h1" fontWeight={600} textAlign={"center"}>
              Privacy Policy
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "70%" }}>
                <Typography variant="body1" textAlign={"justify"}>
                  Annexure related to Privacy Policy <br/>
                  1. Information We Collect <br/>
                  2. Use of Information <br/>
                  3. Sharing of Information <br/>
                  4. Data Retention <br/>
                  5. Your Rights and Choices <br/>
                  6. Security Measures <br/>
                  7.Children&#39;s Privacy <br/>
                  8. Changes to this Policy <br/>
                  9. Contact Us <br/><br/>
                  This Privacy Policy (&quot;Policy&quot;) is designed to inform
                  you (&quot;User,&quot; &quot;you,&quot; or &quot;your&quot;)
                  about how Welcome Onboard (&quot;Company,&quot;
                  &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects,
                  uses, and protects your personal information when you use our
                  website, mobile applications, and related services
                  (collectively, the &quot;Platform&quot;). By accessing or
                  using the Platform, you agree to be bound by this Policy. If
                  you do not agree to the terms of this Policy, you must not
                  access or use the Platform. 
                  <br/><br/><br/>
                  
                  <b>1. Information We Collect </b> <br/>
                  1.1 Information You Provide: <br/>
                  We collect personal information that
                  you voluntarily provide to us, such as your name, email
                  address, phone number, billing information, and other relevant
                  details when you register for an account, make bookings, or
                  interact with our Platform. <br/>
                  1.2 Automatically Collected 
                  Information: <br/>
                  We may automatically collect certain information
                  about your device and usage of the Platform, including your IP
                  address, browser type, operating system, referring URLs, page
                  views, and usage patterns, through cookies, web beacons, and
                  other tracking technologies. <br/><br/>
                  <b>2. Use of Information </b><br/>
                  2.1 We use
                  the information we collect to: <br/>
                  a. Provide and improve our
                  Services; 
                  <br/>
                  b. Process and fulfil bookings and transactions; <br/>
                  c.
                  Communicate with you about your account, bookings, and updates
                  to our Services; <br/>
                  d. Personalize your experience on the
                  Platform; <br/>
                  e. Analyse trends, monitor usage, and enhance the
                  security and performance of the Platform; <br/>
                  f. Comply with legal
                  obligations and enforce our rights and agreements. <br/>
                  <br/>
                  <b>3. Sharing
                  of Information: </b><br/>
                  3.1 We may share your personal information
                  with third parties, including: <br/>
                  a. Service providers and
                  Partners: We may share your information with third-party
                  service providers and business partners who assist us in
                  providing and improving our Services. <br/>
                  b. Affiliates: We may
                  share your information with our affiliates for marketing and
                  promotional purposes, subject to your consent where required
                  by law. <br/>
                  c. Legal Compliance: We may disclose your information
                  when required by law or in response to lawful requests by
                  public authorities. 
                  <br/><br/>
                  <b>4. Data Retention </b> <br/>
                  4.1 We retain your
                  personal information for as long as necessary to fulfil the
                  purposes outlined in this Policy, unless a longer retention
                  period is required or permitted by law. 
                  <br/><br/>
                  <b>5. Your Rights and
                  Choices </b> <br/>
                  5.1 You may have the right to: <br/>
                  a. Access, update, or
                  delete your personal information; <br/>
                  b. Object to the processing
                  of your personal information; <br/>
                  c. Restrict the processing of
                  your personal information; <br/>
                  d. Receive a copy of your personal
                  information in a structured, machine-readable format; <br/>
                  e.
                  Withdraw your consent at any time, if we rely on consent as
                  the legal basis for processing your information. 
                  <br/><br/>
                  <b>6. Security</b><br/>
                  Measures 
                  <br/>6.1 We implement reasonable security measures to
                  protect your personal information from unauthorized access,
                  disclosure, alteration, or destruction. 
                  <br/>6.2 However, please
                  note that no method of transmission over the internet or
                  electronic storage is completely secure, and we cannot
                  guarantee the absolute security of your information. 
                  <br/><br/>
                  <b>7.
                  Children&#39;s Privacy </b><br/>
                  7.1 Our Services are not directed to
                  individuals under the age of 18, and we do not knowingly
                  collect personal information from children under 18. If you
                  are a parent or guardian and believe that your child has
                  provided us with personal information without your consent,
                  please contact us to request deletion of the information.
                  <br/><br/>
                  <b> 8.
                  Changes to this Policy </b><br/>
                   8.1 We reserve the right to update or
                  modify this Policy at any time, in our sole discretion. We
                  will notify you of any changes by posting the updated Policy
                  on the Platform or by other appropriate means. Your continued
                  use of the Platform after such changes constitutes your
                  acceptance of the updated Policy. <br/><br/>
                  <b>9. Contact Us </b>
                  <br/>9.1 If you
                  have any questions or concerns about this Policy or our
                  privacy practices, please contact us at info@welcomeonboard.co (+1(888) 850-9883). By using the Platform, you acknowledge that you have
                  read, understood, and agree to be bound by this Policy. If you
                  do not agree to the terms of this Policy, you must not access
                  or use the Platform.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
        
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
