// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqh2UbMu4oIXqlZJCacZsWrNiFksWxOv4",
  authDomain: "welcome-onboard.firebaseapp.com",
  projectId: "welcome-onboard",
  storageBucket: "welcome-onboard.appspot.com",
  messagingSenderId: "189563638760",
  appId: "1:189563638760:web:dc19c1e565e74f86944a5d",
  measurementId: "G-2YT8ZNVRF1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebase_db = getFirestore(app);