import React from "react";
import { Typography } from "@mui/material";

const TagLine = ({ variant, text, fontWeight }) => {
  return (
    <Typography
      variant={variant}
      style={{
        color: "#9e1e47",
        fontWeight: `${fontWeight}`,
        // fontFamily: "Hind",
        fontFamily: "ITC Bauhaus font",
        textAlign: "right",
      }}
    >
      {text}
    </Typography>
  );
};

export default TagLine;
