import React from "react";
import ChooseUs from "../home/chooseUs/ChooseUs";
import GridSpace from "../footer/components/GridSpace";
import { Grid, Typography } from "@mui/material";
import useChooseUs from "../home/chooseUs/hooks/useChooseUs";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";
import OurServices from "../home/ourServices/OurServices";
import useServices from "../home/ourServices/hooks/useServices";
import Component from "./textTemplate/Component";
import useAboutUs from "./useAboutUs";

const AboutUs = () => {
  const { WhyChooseUs } = useChooseUs();
  const { services } = useServices();
  const { aboutUs, ourMission } = useAboutUs();
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Component title={aboutUs.title} contents={aboutUs.contents} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ChooseUs chooseUs={WhyChooseUs} />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Component title={ourMission.title} contents={ourMission.contents} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <OurServices services={services} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
    </Grid>
  );
};

export default AboutUs;
