import React from "react";
import { Grid } from "@mui/material";
import Flight from "./../../../../assets/AirlinePages/flight.png";
import Field from "./Field";
import CallNowButton from "./CallNowButton";

const Trip = ({ origin, destination, handleCallButton }) => {
  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      sm={12}
      lg={12}
      style={{
        border: "3px solid grey",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Grid item xs={4} md={3} sm={4} lg={3}>
        <Field text={origin} />
      </Grid>
      <Grid item xs={4} md={3} sm={4} lg={3} textAlign={"center"}>
        <img src={Flight} alt="itinerary" width="23%" />
      </Grid>
      <Grid item xs={4} md={3} sm={4} lg={3} textAlign={"center"}>
        <Field text={destination} />
      </Grid>
      <Grid item xs={12} md={3} sm={12} lg={3} textAlign={"center"}>
        <CallNowButton text="Call Now" handleCallButton={handleCallButton} />
      </Grid>
    </Grid>
  );
};

export default Trip;
