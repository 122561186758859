import React from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const StyledTypography = ({ text }) => {
  const StyledText = styled(Typography)({
    fontFamily: "ITC Bauhaus font",
    // fontFamily: "Copperplate, Papyrus, fantasy",
    textAlign: "center",
    padding: "2em",
  });
  return <StyledText variant="h6">{text}</StyledText>;
};

export default StyledTypography;
