import React from "react";
import { Drawer, List } from "@mui/material";
import NavbarDrawerListItem from "./NavbarDrawerListItem";

const NavbarDrawer = ({ pages, isMenuOpen, toggleMenu }) => {
  return (
    <Drawer open={isMenuOpen} onClose={toggleMenu}>
      <List>
        {pages.map((page, index) => (
          <NavbarDrawerListItem
            key={index}
            page={page}
            toggleMenu={toggleMenu}
          />
        ))}
      </List>
    </Drawer>
  );
};

export default NavbarDrawer;
