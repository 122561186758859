import React from "react";
import { Modal, Box, IconButton,useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QueryForm from "../../../home/requirement/queryForm/QueryForm";

const CallbackModal = ({ open, handleClose }) => {
    const isSmallScreen = useMediaQuery("(max-width:600px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 230 :  400,
    // bgcolor: "background.paper",
    border: "5px solid #000",
    borderRadius: 16,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background:
        "linear-gradient(180deg, rgba(237,234,219,10) 0%, rgba(149,216,219,.9) 100%)",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 3,
            right: 35,
          }}
        >
          <CloseIcon />
        </IconButton>
        <QueryForm />
      </Box>
    </Modal>
  );
};

export default CallbackModal;
