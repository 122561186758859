import React from "react";

const useRefundPolicy = () => {
  return {
    policy: [
      "ZERO Cancellation service is valid only on selected " +
        "flights booked on welcome onboard",
      "The amount collected for availing cancellation service " +
        "Fee will be non-refundable.",
      "The customer will be eligible for the complete waiver " +
        "for selected flights only if he cancels the ticket " +
        "within 24 hours of booking. Refund will be credited within 7 days of " +
        "the cancellation.",
      "The user is not elligible of any refund after the use of our services",
      "The user is not elligible of any refund, if they have booked any of our service" +
        " but fails to avail them.",
      "In case of cancellation, waiver will not be given & " +
        "normal cancellation penalties will be charged. ",
      "In the event of any misuse or abuse of the offer by the " +
        "customer or travel agent, Welcome Onboard reserves the " +
        "right to deny the offer or cancel the booking. Welcome " +
        "Onboard shall not refund the booking amount in such cases. ",
      "Customers, who are travel agents by occupation, are barred " +
        "from making bookings for their customers and Welcome Onboard" +
        " reserves the right to deny the offer or cancel the booking. " +
        "Welcome Onboard shall not refund the booking amount in such " +
        "cases.",
      "Welcome Onboard is the sole authority for the interpretation " +
        "of these terms",
    ],
  };
};

export default useRefundPolicy;
