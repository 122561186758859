import React from 'react'
import SliderButton from "./SliderButton";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useMediaQuery } from "@mui/material";

const PrevSliderButton = ({fn}) => {
    const isSmallScreen = useMediaQuery("(max-width:768px)");
  return (
    <>
    {isSmallScreen ? (
        <SliderButton
          icon={<ArrowBackIosNewRoundedIcon fontSize="small" />}
          onClickFn={fn}
        />
      ) : (
        <SliderButton
          icon={<ArrowBackIosNewRoundedIcon fontSize="large" />}
          onClickFn={fn}
        />
      )}
      </>
  )
}

export default PrevSliderButton;