import { Grid } from "@mui/material";
import React from "react";
import FaqImage from "./../../../../assets/AirlinePages/faq.png";

const FaqIcon = () => {
  return (
    <Grid container item xs={12} sm={12} lg={4} md={4}>
      <div style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <img src={FaqImage} alt="Frequently Asked Questions" width="70%" height="400px"/>
      </div>
    </Grid>
  );
};

export default FaqIcon;
