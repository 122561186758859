import React from "react";
import { Box, Divider } from "@mui/material";

const DividerDesign = ({flex}) => {
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: `${flex}`,
        alignItems: "center",
      }}
    >
      <Divider
        sx={{
          my: 3,
          // color: "#2582c2",
          borderColor: "#2582c2",
          borderWidth: 2,
          minWidth: "70%",
        }}
      />
    </Box>
  );
};

export default DividerDesign;
