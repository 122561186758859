import { Typography } from "@mui/material";
import React from "react";

const Disclaimer = ({ disclaimer }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "90%" }}>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          {disclaimer.topic}
        </Typography>

        <Typography
          variant="body2"
          style={{ fontWeight: 400, textAlign: "justify" }}
        >
          {disclaimer.description}
        </Typography>
      </div>
    </div>
  );
};

export default Disclaimer;
