import useSpiritItinerary from "../airlinesPages/Itinerary/useSpiritItinerary";
import useDeltaItinerary from "../airlinesPages/Itinerary/useDeltaItinerary";

const useFlights = () => {
  const { list: spiritItineraryList } = useSpiritItinerary();
  const { list: deltaItineraryList } = useDeltaItinerary();
  return {
    list: Array.from(new Set([...spiritItineraryList, ...deltaItineraryList])),
  };
};

export default useFlights;
