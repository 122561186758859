import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import styles from "./styles/CompanyStyles";

const Company = ({ logo, tagLine }) => {
  const classes = styles();
  return (
    <>
      <Link to="/">
        <div className={classes.items}>
          <img src={logo} alt="app logo" width={"45%"} height={"auto"} />
        </div>
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "50%" }}>
          <Typography
            variant="body1"
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            {tagLine}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Company;
