import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  toolbarDiv: {
    padding: "0em 1em",
    // maxWidth: "100%",
    minWidth: "100%",
    // minWidth: "100%",
    maxHeight: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  appImage: {
    width: "100%"
  },
  items:{
    paddingBottom: "1.5%",
    width: "100%",
  }
}));
