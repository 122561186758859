import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./styles/CompanyLogoStyles";

const CompanyLogo = ({ AppIcon, isSmallScreen, isMediumScreen }) => {
  const classes = styles();
  return (
    <Grid item xs={10} md={5} sm={10} lg={4}>
      <Link to="/" className={classes.appImage}>
        <div
          className={classes.items}
          style={
            isSmallScreen
              ? {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          <img
            src={AppIcon}
            alt="company logo"
            width={
                isSmallScreen ? "40%" : isMediumScreen ? "80%" : "35%"}
            height={"auto"}
          />
        </div>
      </Link>
    </Grid>
  );
};

export default CompanyLogo;
