import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import "./slick.css";
import "./slick-theme.css";
import useBgImages from "./../../../assets/BgImages";

const useStyles = makeStyles((theme) => ({
  offerSlider: {
    width: "100%",
  },
}));

const OfferImageSlider = () => {
  const classes = useStyles();
  const { images } = useBgImages();
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings} className={classes.offerSlider}>
      {images.map((imageUrl, index) => (
        <img
          key={index}
          src={`${imageUrl}`}
          width={"100%"}
          height={"auto"}
          alt={`${imageUrl}`}
        />
      ))}
    </Slider>
  );
};

export default OfferImageSlider;
