import React from "react";
import { Link } from "react-router-dom";

const NavbarButton = ({ btnText, to }) => {
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top when the link is clicked
  };

  return (
    <Link
      to={to}
      style={{ textDecoration: "none", color: "white", fontSize: "20px" }}
      onClick={handleClick}
    >
      {btnText}
    </Link>
  );
};

export default NavbarButton;
