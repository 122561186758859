
const useAboutUs = () => {
  return {
    ourMission: {
      title: "Our Mission",
      contents: [
        "Our mission at Welcome Onboard is simple: to make your travel" +
          " dreams a reality. We are dedicated to delivering exceptional" +
          " service, unparalleled convenience, and unbeatable value to each" +
          " and every one of our clients. Whether you're planning a romantic" +
          " getaway, a family vacation, or a corporate retreat, we are" +
          " committed to exceeding your expectations and ensuring a seamless" +
          " travel experience from start to finish.",

        "At Welcome Onboard, we understand that no two travelers are" +
          " alike, which is why we go above and beyond to personalize every" +
          " aspect of your journey. Our team of experienced travel experts" +
          " is here to listen to your unique preferences, offer expert" +
          " advice, and craft custom-tailored itineraries that reflect your" +
          " individual tastes and interests. From luxury escapes to" +
          " budget-friendly adventures, we have the knowledge, resources," +
          " and connections to curate the perfect trip for you.",
      ],
    },
    aboutUs: {
      title: "About Us",
      contents: [
        "At Welcome Onboard, we are devoted to being your trusted" +
          " provider for all of your vacation requirements, offering" +
          " comprehensive and inclusive services that span from meticulously" +
          " crafted tour packages and exquisite hotel accommodations to" +
          " seamless flight ticket reservations, thrilling excursions, and" +
          " hassle-free car rentals. With our extensive experience in the" +
          " travel industry, we have proudly established ourselves as one of" +
          " the premier vacation companies in the United States, renowned" +
          " for our exceptional services.",

        "We believe that every journey should be more than just a trip;" +
          " it should be an adventure filled with enriching experiences and" +
          " cherished memories. At Welcome Onboard, excellence is not just a" +
          " goal; it's our standard. We are committed to providing you with" +
          " the highest level of service, professionalism, and attention to" +
          " detail throughout your entire journey. From the moment you reach" +
          " out to us to the moment you return home, you can trust that we" +
          " will be by your side every step of the way, ensuring a seamless" +
          " and stress-free travel experience.",

        "With years of expertise in the travel industry, we specialize in" +
          " providing comprehensive travel solutions tailored to meet your" +
          " every need. At the heart of Welcome Onboard is a passion for" +
          " building lasting relationships with our clients. We believe that" +
          " trust, integrity, and transparency are the foundation of any" +
          " successful partnership, which is why we strive to foster open" +
          " communication, mutual respect, and genuine connections with each" +
          " and every traveler we serve. Your satisfaction is our top" +
          " priority, and we will stop at nothing to earn your loyalty and" +
          " exceed your expectations time and time again.",
      ],
    },
  };
};

export default useAboutUs;
