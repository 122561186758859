import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  copyright: {
    // textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "",
    alignItems: "center",
    mt: 2,
  },
  dividerContainer: {
    width: "98%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
