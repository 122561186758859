const useFaqContents = () => {
  return {
    faqs: [
      {
        question: "How can I find the best deals on airline tickets?",
        answer:
          "To find the best deals on airline tickets, call us on our "+
          "Toll-free number: +1(888) 850-9883 for quick response, "+
          "or you can email us at info@welcomeonboard.co, we will reach back" + 
          " to you within 24 hours.",
      },
      {
        question: "When is the optimal time to purchase airline tickets?",
        answer:
          "The optimal time to purchase airline tickets is typically" +
          " on weekdays. For domestic flights, booking around 30 days in " +
          "advance is advisable, while for international travel, " +
          "approximately 60 days prior to departure is recommended for " +
          "securing cost-effective deals.",
      },
      {
        question: "How many hours in advance can you book flight tickets?",
        answer:
          "Generally, you can book flight tickets up to 2 hours " +
          "before departure. However, this timeframe may vary depending on" +
          " the airline's policies. Some airlines may allow online bookings" +
          " closer to departure, while others might have stricter regulations.",
      },
      {
        question: "What factors influence the cost of a flight ticket?",
        answer:
          "Several factors influence the cost of flight tickets, " +
          "including airline choice, booking timing, destination, seasonality," +
          " demand, fuel prices, and seat availability. Whether it's an advance" +
          " booking or last-minute reservation also plays a significant role.",
      },
      {
        question: "Are weekday bookings cheaper than weekend bookings?",
        answer:
          "Yes, generally, weekday bookings are cheaper than weekend " +
          "bookings. Airlines often offer lower fares for flights booked on " +
          "weekdays, particularly on Tuesdays and Wednesdays.",
      },
      {
        question: "Can flight prices fluctuate after booking?",
        answer:
          "Yes, flight prices can fluctuate after booking, especially " +
          "for reservations made well in advance. Airlines may adjust prices " +
          "based on factors such as demand, seat availability, and market " +
          "conditions. Some airlines offer price protection or allow changes " +
          "to bookings if prices decrease.",
      },
      {
        question:
          "Are there any additional fees or charges when booking " +
          "airline tickets?",
        answer:
          "Yes, there may be additional fees or charges when booking " +
          "airline tickets, including baggage fees, seat selection fees, " +
          "cancellation or change fees, and taxes. It's important to review " +
          "the terms and conditions carefully and understand any extra charges" +
          " before completing the booking.",
      },
    ],
  };
};

export default useFaqContents;
