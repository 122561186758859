const useNavbar = () => {
  return {
    pages: [
      {
        name: "Home",
        address: "/",
      },
      {
        name: "Destinations",
        address: "/destinations",
      },
      {
        name: "Flights",
        address: "/flights",
      },
      {
        name: "Packages",
        address: "/packages",
      },
    ],
  };
};

export default useNavbar;
