import Alaska from "./USA_VacationDestinations/alaska.webp";
import GolderGateBridge from "./USA_VacationDestinations/Golden-Gate-Bridge-San-Francisco.webp";
import NewYork from "./USA_VacationDestinations/New York.webp";
import CentralPark from "./USA_VacationDestinations/New York-  Central Park.webp";
import NiagaraFalls from "./USA_VacationDestinations/Niagara-Falls.webp";
import Selangor from "./USA_VacationDestinations/Selangor -Malaysia.webp";
import CanyonWestSidewalk from "./USA_VacationDestinations/skywalk-grand-canyon-west.webp";
import Disney from "./USA_VacationDestinations/Walt Disney.webp";
import WhiteHouse from "./USA_VacationDestinations/White House.webp";
import Yosemite from "./USA_VacationDestinations/yosemite-hero.webp";
import CraterLake from "./USA_VacationDestinations/Crater Lake, Oregon.webp";
import FenwayPark from "./USA_VacationDestinations/Fenway Park, Massachusetts.webp";
import ForsythPark from "./USA_VacationDestinations/Forsyth Park, Georgia.webp";
import FreedomTrail from "./USA_VacationDestinations/Freedom Trail, Boston.webp";
import HagleyMuseum from "./USA_VacationDestinations/Hagley Museum, Delaware.webp";
import Hollywood from "./USA_VacationDestinations/Hollywood, Los Angeles.webp";
import JacksonSquare from "./USA_VacationDestinations/Jackson Square, New Orleans.webp";
import KansasCity from "./USA_VacationDestinations/Kansas City Zoo, Missouri.webp";
import KenaiFjords from "./USA_VacationDestinations/Kenai Fjords National Park, Alaska.webp";
import LostRiverCave from "./USA_VacationDestinations/Lost River Cave, Kentucky.webp";
import MauiBeach from "./USA_VacationDestinations/Maui Beach, Hawaii.webp";
import MuseumOfArt from "./USA_VacationDestinations/Museum of Art, Philadelphia.webp";
import MyrtleBeach from "./USA_VacationDestinations/Myrtle Beach, South Carolina.webp";
import SeaWorld from "./USA_VacationDestinations/Sea World, San Diego.webp";
import SpaceNeedle from "./USA_VacationDestinations/Space Needle, Seattle.webp";
import StatenIsland from "./USA_VacationDestinations/Staten Island Ferry, New Jersey.webp";
import StatueOfLiberty from "./USA_VacationDestinations/Statue of Liberty, New York.webp";
import Strip from "./USA_VacationDestinations/Strip, Las Vegas.webp";
import Alamo from "./USA_VacationDestinations/The Alamo, Texas.webp";
import Breakers from "./USA_VacationDestinations/The Breakers, Rhode Island.webp";
import WillisTower from "./USA_VacationDestinations/Willis Tower, Chicago.webp";
import Yellowstone from "./USA_VacationDestinations/Yellowstone National Park, Montana.webp";

const usaTopVacationDestinations = () => {
  return {
    destinations: [
      {
        url: Yellowstone,
        title: "Yellowstone National Park, Montana (US)",
      },
      {
        url: WillisTower,
        title: "Willis Tower, Chicago (US)",
      },
      {
        url: Breakers,
        title: "The Breakers, Rhode Island (US)",
      },
      {
        url: Alamo,
        title: "The Alamo, Texas (US)",
      },
      {
        url: Strip,
        title: "Strip, Las Vegas (US)",
      },
      {
        url: StatueOfLiberty,
        title: "Statue of Liberty, New York (US)",
      },
      {
        url: StatenIsland,
        title: "Staten Island Ferry, New Jersey (US)",
      },
      {
        url: SpaceNeedle,
        title: "Space Needle, Seattle (US)",
      },
      {
        url: SeaWorld,
        title: "Sea World, San Diego (US)",
      },
      {
        url: MyrtleBeach,
        title: "Myrtle Beach, South Carolina (US)",
      },
      {
        url: MuseumOfArt,
        title: "Museum of Art, Philadelphia (US)",
      },
      {
        url: Hollywood,
        title: "Hollywood, Los Angeles (US)",
      },
      {
        url: MauiBeach,
        title: "Maui Beach, Hawaii (US)",
      },
      {
        url: LostRiverCave,
        title: "Lost River Cave, Kentucky (US)",
      },
      {
        url: KenaiFjords,
        title: "Kenai Fjords National Park, Alaska (US)",
      },
      {
        url: KansasCity,
        title: "Kansas City Zoo, Missouri (US)",
      },
      {
        url: JacksonSquare,
        title: "Jackson Square, New Orleans (US)",
      },
      {
        url: ForsythPark,
        title: "Forsyth Park, Georgia (US)",
      },
      {
        url: HagleyMuseum,
        title: "Hagley Museum, Delaware (US)",
      },
      {
        url: FreedomTrail,
        title: "Freedom Trail, Boston (US)",
      },
      {
        url: NewYork,
        title: "Empire State Building, New York (US)",
      },
      {
        url: CraterLake,
        title: "Crater Lake, Oregon (US)",
      },
      {
        url: FenwayPark,
        title: "Fenway Park, Massachusetts (US)",
      },
      {
        url: Selangor,
        title: "Selangor, Malaysia",
      },
      {
        url: CanyonWestSidewalk,
        title: "Grand Canyon West Sidewalk, Arizona  (US)",
      },
      {
        url: GolderGateBridge,
        title: "Golden Gate Bridge, San Francisco  (US)",
      },
      {
        url: NiagaraFalls,
        title: "Niagara Falls, Ontario, Canada",
      },
      {
        url: Alaska,
        title: "Denali National Park and Preserve, Alaska (US)",
      },
      {
        url: CentralPark,
        title: "Central Park, New York (US)",
      },
      {
        url: Yosemite,
        title: "Yosemite National Park, California (US)",
      },
      {
        url: Disney,
        title: "Disney World, Florida (US)",
      },
      {
        url: WhiteHouse,
        title: "White House, Washington, DC (US)",
      },
    ],
  };
};

export default usaTopVacationDestinations;
