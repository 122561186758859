import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

const FaqQnA = ({ qna, panelIndex, expanded, isExpanded, handleChange }) => {
  return (
    <Accordion expanded={isExpanded === expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panelIndex}bh-content`}
        id={`panel${panelIndex}bh-header`}
        sx={{
          backgroundColor: "#baf5f5",
        }}
      >
        <Typography>{qna.question}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "wheat",
          // backgroundColor: "#d7fc32",
        }}
      >
        <Typography>{qna.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqQnA;
