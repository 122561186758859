import { Grid, Typography } from "@mui/material";
import React from "react";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";
import allAirlines from "../../assets/Airlines/allAirlines";
import AirlinesFlights from "./../home/flights/Flights";
import Itinerary from "../airlinesPages/components/itinerary/Itinerary";
import useFlights from "./useFlights";

const Flights = () => {
  const { airlines } = allAirlines();
  const { list } = useFlights();
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Typography variant="h1" fontWeight={600} textAlign={"center"}>
            Flights
          </Typography>
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AirlinesFlights airlines={airlines} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Itinerary list={list} />
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default Flights;
