import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    toolbarDiv: {
        padding: "0em 1em",
        minWidth: "100%",
        maxHeight: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
}));
