import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const ServiceCard = ({ icon, title, description }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <Grid item lg={4} md={4} sm={5} xs={6}>
      <Card
        sx={{
          width: "100%",
          height: isSmallScreen ? "230px" : "180px",
          backgroundColor: "transparent",
          padding: "1.5em 0em",
          border: "3px solid #ffcc00",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            color: "white",
          }}
        >
          <img
            src={`${icon}`}
            alt={`${title}`}
            style={{
              width: "30%",
              objectFit: "cover",
              marginBottom: "2em",
            }}
          />
          <Typography
            variant="body1"
            style={{
              color: "#ffcc00",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" style={{ textAlign: "center" }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ServiceCard;
