import React from "react";
import CherryPointCard from "./CherryPointCard";
import useCherryPoints from "./hooks/useCherryPoint";
import { Grid } from "@mui/material";

const CherryPoints = () => {
  const { services } = useCherryPoints();
  return (
    <div
      style={{
        backgroundColor: "#2582c2",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "1.5em 0em",
      }}
    >
      <Grid container item xs={10} sm={10} md={8} lg={8} style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {services.map((service, index) => (
        <CherryPointCard
          key={index}
          title={service.title}
          description={service.description}
        />
      ))}
      </Grid>
    </div>
  );
};

export default CherryPoints;
