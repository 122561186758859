const useJetBlueItinerary = () => {
  return {
    list: [
        {
          origin: "San Francisco",
          destination: "Seattle",
        },
        {
          origin: "Chicago",
          destination: "Denver",
        },
        {
          origin: "New Orleans",
          destination: "Houston",
        },
        {
          origin: "Los Angeles",
          destination: "Portland",
        },
        {
          origin: "Grand Canyon",
          destination: "Las Vegas",
        },
        {
          origin: "Key West",
          destination: "Miami",
        },
        {
          origin: "Philadelphia",
          destination: "Washington D.C.",
        },
        {
          origin: "Yellowstone National Park",
          destination: "Salt Lake City",
        },
      ]
  }
}

export default useJetBlueItinerary;