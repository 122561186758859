import React from "react";
import { Box, Container, Grid } from "@mui/material";
import styles from "./styles/FooterStyles";
import SocialMediaLinks from "./links/socialMedia/socialMediaLinks";
import useFooter from "./useFooter";
import StackColumn from "./links/StackColumn";
import GridSpace from "./components/GridSpace";
import { Divider } from "@mui/material";
import Copyright from "./components/Copyright/Copyright";
import CallBackFromUs from "./components/callBackFromUs/CallBackFromUs";
import Company from "./components/company/Company";
import Disclaimer from "./components/disclaimer/Disclaimer";

const Footer = () => {
  const classes = styles();
  const {
    column1,
    column2,
    column3,
    callBackFromUs,
    company,
    socialMedias,
    companyCopyright,
    disclaimer,
  } = useFooter();

  return (
    <Box className={classes.box}>
      <Container maxWidth="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={12} lg={3}>
            <Company logo={company.logo} tagLine={company.tagLine} />
          </Grid>
          <Grid item xs={12} md={3} sm={4} lg={3} justifyContent="center">
            <StackColumn data={column1} />
          </Grid>
          <Grid item xs={12} md={3} sm={4} lg={3} justifyContent="center">
            <StackColumn data={column2} />
          </Grid>
          <Grid item xs={12} md={3} lg={3} sm={4} justifyContent="center">
            <StackColumn data={column3} />
            {/* <Disclaimer disclaimer={disclaimer} /> */}
            <GridSpace />
          </Grid>
        </Grid>

        <GridSpace />

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <CallBackFromUs
              text={callBackFromUs.txt}
              buttonText={callBackFromUs.btn}
            />
          </Grid>
        </Grid>

        <GridSpace />

        <Grid item xs={12} md={6} lg={12} sm={12}>
          <Grid
            container
            spacing={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <SocialMediaLinks socialMedias={socialMedias} />
          </Grid>
        </Grid>

        <Box className={classes.dividerContainer}>
          <Divider sx={{ my: 3, borderColor: "grey", borderWidth: 2 }} />
        </Box>

        <Copyright
          companyCopyright={companyCopyright.copyrightSince}
          companyHeadquarters={companyCopyright.companyHeadquarters}
          fareOnWebsite={companyCopyright.fareOnWebsite}
        />

        <GridSpace />

        <Box className={classes.dividerContainer}>
          <Divider sx={{ my: 3, borderColor: "grey", borderWidth: 2 }} />
        </Box>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <GridSpace />
          <Disclaimer disclaimer={disclaimer} />
        </Grid>
        <GridSpace />
      </Container>
    </Box>
  );
};

export default Footer;
