import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

const Topic = ({ topic }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isSmallScreen ? (
        <Typography
        variant={"h4"}
        style={{
          color: "#2582c2",
          fontWeight: 600,
          fontFamily: "ITC Bauhaus font",
          textAlign: "center",
        }}
      >
        {topic}
      </Typography>
      ) : (
      <Typography
        variant={"h2"}
        style={{
          color: "#2582c2",
          fontWeight: 600,
          fontFamily: "ITC Bauhaus font",
          textAlign: "center",
        }}
      >
        {topic}
      </Typography>
      )}
    </Box>
  );
};

export default Topic;
