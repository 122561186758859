import React from "react";
import useAirlines from "./useAirlines";
import { Route, Routes } from "react-router-dom";
import AirlinePage from "./AirlinePage";

const AirlinePages = () => {
  const { airlines } = useAirlines();
  return (
      <Routes>
      {airlines.map((airline, index) => {
        return (
          <Route
            key={index}
            path={airline.route}
            exact
            element={<AirlinePage airline={airline} />}
          />
        );
      })}
      </Routes>
  );
};

export default AirlinePages;
