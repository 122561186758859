import React from "react";

const useTermsAndConditions = () => {
  return {
    tnc: [
      'These Terms of Service ("TOS") govern your use of ' +
        'the Welcome Onboard ("Company","we", ' +
        '"us", or "our") website, mobile ' +
        "applications, and related services (collectively, the " +
        '"Platform"). By accessing or using the Platform, you ' +
        "agree to be bound by these TOS. If you do not agree to" +
        " all the terms and conditions of these TOS, you must" +
        " not access or use the Platform. ",
      ,
      [
        {
          topic: "Use of the Platform",
          desc: [
            "You must be at least 18 years old to access or use the" +
              " Platform. By accessing or using the Platform, you " +
              "represent and warrant that you are at least 18 years old.",
            "You agree to use the Platform for lawful purposes only and" +
              " in accordance with these TOS and all applicable laws and " +
              "regulations.",

            "You are solely responsible for maintaining the " +
              "confidentiality of your account credentials and for all " +
              "activities that occur under your account.",
          ],
        },
        {
          topic: "User Conduct",
          desc: {
            topic: "You agree not to: ",
            desc: [
              "Violate any applicable laws or regulations",
              "Infringe upon the rights of others",
              "Transmit any content that is unlawful, " +
                "defamatory, obscene, offensive, or " +
                "otherwise objectionable",
              "Interfere with or disrupt the operation " +
                "of the Platform or the networks connected" +
                " to the Platform",
              "Engage in any conduct that could damage, " +
                "disable, overburden, or impair the Platform " +
                "or interfere with any other party's use " +
                "and enjoyment of the Platform.",
            ],
          },
        },
        {
          topic: "Intellectual Property Rights",
          desc: [
            "All content and materials available on the Platform," +
              "including but not limited to text, graphics, logos, images, " +
              "audio clips, video clips, and data compilations, are the " +
              "property of the Company or its licensors and are protected by " +
              "copyright, trademark, and other intellectual property laws. ",
            "You may not modify, reproduce, distribute, transmit, " +
              "display, perform, publish, license, create derivative works " +
              "from, transfer, or sell any content or materials obtained from " +
              "the Platform without the prior written consent of the Company.",
          ],
        },
        {
          topic: "Limitation of Liability",
          desc: [
            "To the fullest extent permitted by law, in no event shall " +
              "the Company, its affiliates, or their respective officers, " +
              "directors, employees, agents, or licensors be liable for any " +
              "indirect, incidental, special, consequential, or punitive " +
              "damages, including without limitation, loss of profits, data, " +
              "use, goodwill, or other intangible losses, arising out of or " +
              "in connection with your access to or use of the Platform or " +
              "the Services. ",
            "In no event shall the aggregate liability of the Company, " +
              "its affiliates, or their respective officers, directors, " +
              "employees, agents, or licensors exceed the amount paid by you " +
              "to the Company for the Services giving rise to the claim.",
          ],
        },
        {
          topic: "Indemnification",
          desc: [
            "You agree to indemnify, defend, and hold harmless the " +
              "Company, its affiliates, and their respective officers, " +
              "directors, employees, agents, and licensors from and against " +
              "any and all claims, liabilities, damages, losses, costs, " +
              "expenses, or fees (including reasonable attorneys' fees) " +
              "arising out of or in connection with your use of the Platform " +
              "or any violation of these TOS. ",
          ],
        },
        {
          topic: "Termination",
          desc: [
            "We reserve the right to terminate or suspend your access " +
              "to the Platform and/or your account at any time for any " +
              "reason, with or without cause, and without prior notice.",
            "Upon termination or suspension, your right to access and " +
              "use the Platform and any Services will immediately cease, and " +
              "we may deactivate or delete your account and all related " +
              "information and files.",
          ],
        },
        {
          topic: "Governing Law and Dispute Resolution",
          desc: [
            "These TOS shall be governed by and construed in accordance " +
              "with the laws of [Jurisdiction], without regard to its " +
              "conflict of law principles.",
            "Any dispute arising out of or in connection with these TOS " +
              "shall be resolved exclusively through binding arbitration " +
              "administered by the [Arbitration Institution], in accordance " +
              "with its rules and procedures.",
            "The arbitration shall take place in [Location], and the " +
              "language of the arbitration shall be [Language].",
            "The decision of the arbitrator(s) shall be final and " +
              "binding on the parties and may be enforced in any court of " +
              "competent jurisdiction. ",
          ],
        },
        {
          topic: "Miscellaneous",
          desc: [
            "This Agreement constitutes the entire agreement between " +
              "you and the Company regarding your use of the Platform and " +
              "supersedes all prior or contemporaneous communications, " +
              "agreements, or representations, whether oral or written, " +
              "between you and the Company.",
            "If any provision of these TOS is found to be invalid, " +
              "illegal, or unenforceable, the remaining provisions shall " +
              "continue in full force and effect.",
            "The failure of the Company to enforce any right or " +
              "provision of these TOS shall not constitute a waiver of such " +
              "right or provision.",
            "These TOS may be amended or modified by the Company from " +
              "time to time in its sole discretion. Any such amendments or " +
              "modifications will be effective upon posting on the Platform.",
          ],
        },
      ],
      "Your continued use of the Platform after such posting shall "+
      "constitute your acceptance of the amended or modified TOS. By "+
      "using the Platform, you acknowledge that you have read, "+
      "understood, and agree to be bound by these TOS. If you do not "+
      "agree to all the terms and conditions of these TOS, you must" +
      " not access or use the Platform.",
    ],
  };
};

export default useTermsAndConditions;
