import { Grid } from "@mui/material";
import React from "react";
import DestinationCard from "./DestinationCard";

const DestinationsList = ({ list }) => {
  const handleCallButton = () => {
    window.open("tel:" + "+1(888) 850-9883");
  };

  const colors = ["#ffcc00" , "#2582c2"];
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Grid item lg={9} md={9} sm={11} xs={11}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {list.map((destination, index) => (
            <DestinationCard
              key={index}
              destination={destination}
              handleCallButton={handleCallButton}
              textColor={index%2 === 0 ? colors[0] : colors[1]}
              divBgColor={index%2 === 0 ? colors[1] : colors[0]}
            />
          ))}
        </div>
      </Grid>
    </div>
  );
};

export default DestinationsList;
