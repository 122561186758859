const useServicesOffered = () => {
  return {
    services: [
      {
        title: "Free Cancellation",
        description:
          "If you want to cancel your flight ticket, you can easily " +
          "cancel it within 24 hours of booking and the amount will be refunded " +
          "into your account in 7 working days. We ensure that you do not face any " +
          "hindrance in getting your amount back.",
      },
      {
        title: "24x7 Customer Support",
        description: "We have a dedicated team that is available "+
        "24X7 to solve all your queries and concerns related to online"+
        " air ticket bookings. You can contact us on our toll-free "+
        "number tel: +1(888) 850-9883",
      },
    ],
  };
};

export default useServicesOffered;
