import "./contactUs.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "./../home/callTollFree/CallTollFreeNo";
import QueryFormServiceApi from "../home/requirement/queryForm/service/QueryFormServiceApi";
import AddQueryConfirmation from "../home/requirement/queryForm/confirmation/Confirmation";

const { contactUS } = QueryFormServiceApi();

const ContactUsSection = () => {
  const [showAddQueryConfirmation, setShowAddQueryConfirmation] =
    useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Send form data to contactUS function
      await contactUS(values);

      // Set showAddQueryConfirmation to true
      setShowAddQueryConfirmation(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false); // Ensure to reset form submission state
    }
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <div className="contact-us">
        <div className="contact container">
          <Formik
            initialValues={{ name: "", email: "", message: "" }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form">
                  <div className="form-txt">
                    <h1>Contact Us</h1>
                    <span>
                      As you might expect of a company that began as a high-end
                      interiors contractor, we pay strict attentionAt welcome
                      onboard, Our team is dedicated to providing you with the
                      best travel experiences, from unforgettable adventures to
                      serene getaways. Whether you're planning your next trip or
                      need assistance on the road, we're here to help every step
                      of the way.
                    </span>
                    <h3>USA</h3>
                    <p>
                      19615 42RD Flushing, NY 11358
                      <br />
                      +1(888) 850-9883
                    </p>
                  </div>
                  <div className="form-details">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                    <ErrorMessage name="name" component="div" />

                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                    <ErrorMessage name="email" component="div" />

                    <Field
                      as="textarea"
                      name="message"
                      rows="7"
                      placeholder="Message"
                      required
                    />
                    <ErrorMessage name="message" component="div" />

                    <button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Sending..." : "SEND MESSAGE"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <AddQueryConfirmation
          open={showAddQueryConfirmation}
          onClose={() => {
            setShowAddQueryConfirmation(false);
          }}
        />
      </div>

      <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
        
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
    </Grid>
  );
};

// Define MapSection component
const MapSection = () => {
  return (
    <section className="map_sec">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="map_inner">
              <h4>Find Us on Google Map</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tempore quo beatae quasi assumenda, expedita aliquam minima
                tenetur maiores neque incidunt repellat aut voluptas hic dolorem
                sequi ab porro, quia error.
              </p>
              <div className="map_bind">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { ContactUsSection, MapSection };
