import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  items: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
