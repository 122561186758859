import React from "react";
import LinkButton from "./../../../formik/formikLinkButton/LinkButton";

const SocialMediaLinks = ({ socialMedias }) => {
  return (
    <div
      style={{
        maxWidth: "60%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {socialMedias.map((socialMedia, index) => (
        <LinkButton
          key={index}
          url={socialMedia.to}
          icon={socialMedia.icon}
          text={socialMedia.text}
        />
      ))}
    </div>
  );
};

export default SocialMediaLinks;
