import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  FormLabel,
} from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

const FormikRadioSelect = (props) => {
  const { values, name, label, width } = props;
  const [field, meta] = useField(name);
  const { value, onChange } = field;
  const { error, touched } = meta;

  return (
    <div
      style={{
        minWidth: `${width}`,
        margin: ".5em 0em",
        color: "black",
        fontWeight: 550,
      }}
    >
      <FormControl component="fieldset">
        <FormLabel id={name}
          style={{ fontWeight: 550, color: "black", fontSize: "20px" }}
        >
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          name={name}
          value={value}
          onChange={onChange}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {values.map((val, index) => (
              <FormControlLabel
                key={index}
                value={val.value}
                control={<Radio />}
                label={val.label}
              />
            ))}
          </div>
        </RadioGroup>
        {touched &&
          error && ( 
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
      </FormControl>
    </div>
  );
};

FormikRadioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default FormikRadioSelect;
