import { Grid } from "@mui/material";
import React from "react";
import Heading from "./Heading";
import Trip from "./Trip";

const Itinerary = ({list}) => {
  const handleCallButton = () => {
    window.open("tel:" + "+1(888) 850-9883");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        item
        xs={10}
        md={8}
        sm={10}
        lg={8}
        style={{
          border: "3px solid grey",
        }}
      >
        <Heading />
        {list.map((tripItinerary, index) => (
          <Trip
            key={index}
            origin={tripItinerary.origin}
            destination={tripItinerary.destination}
            handleCallButton={handleCallButton}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Itinerary;
