import React from "react";
import { Box, Grid } from "@mui/material";
import DividerDesign from "../divider/Divider";
import Topic from "../topic/Topic";

const Title = ({ title }) => {
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item lg={3} md={3} sm={2} xs={1}>
        <DividerDesign flex={"flex-end"} />
      </Grid>
      <Grid item lg={6} md={6} sm={8} xs={10}>
        <Topic topic={title} />
      </Grid>
      <Grid item lg={3} md={3} sm={2} xs={1}>
        <DividerDesign flex={"flex-start"}/>
      </Grid>
    </Box>
  );
};

export default Title;
