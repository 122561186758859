import { Form, Formik } from "formik";
import React, { useState } from "react";
import { initialValues, queryFormSchema } from "./service/QueryFormService";
import { Typography, useMediaQuery } from "@mui/material";
import FormikTextField from "./../../../formik/formikTextField/FormikTextField";
import FormikRadioSelect from "./../../../formik/formikRadioSelect/FormikRadioSelect";
import FormikButton from "./../../../formik/formikButton/FormikButton";
import AddQueryConfirmation from "./confirmation/Confirmation";
import useQueryForm from "./hooks/useQueryForm";
import "./styles.css";

const QueryForm = ({ offer, code, btnName }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  const [queryFormFields, setQueryFormFields] = useState(initialValues());
  const [showAddQueryConfirmation, setShowAddQueryConfirmation] =
    useState(false);

  const { error, handleAddQuery } = useQueryForm();

  const handleSubmit = (values, { resetForm }) => {
    handleAddQuery(values, setShowAddQueryConfirmation);

    resetForm();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={queryFormFields}
        onSubmit={handleSubmit}
        validationSchema={queryFormSchema}
      >
        <Form
          style={{
            width: "90%",
            // padding: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1em",
          }}
        >
          <Typography
            variant={isSmallScreen ? "body2" : isMediumScreen ? "body1" : "h6"}
            style={{
              fontWeight: 550,
              color: "green",
              animationName: "blinkAnimation",
              animationDuration: "2s",
              animationIterationCount: "infinite",
            }}
          >
            {offer}
            <br />
            {code}
          </Typography>
          <div style={{ height: "1em" }}></div>
          <FormikTextField
            margin="dense"
            name={"customer_name"}
            label={"Name"}
            style={{ minWidth: "80%" }}
          />
          <FormikTextField
            margin="dense"
            name={"email"}
            label={"Email"}
            style={{ minWidth: "80%" }}
            // autoComplete="email"
          />
          <FormikTextField
            margin="dense"
            name={"phone"}
            label={"Phone"}
            style={{ minWidth: "80%" }}
            // autoComplete="tel"
          />
          {/* <FormikTextField
            margin="dense"
            name={"query"}
            label={"Looking For"}
            style={{ minWidth: "80%" }}
          /> */}
          {/* drop down  */}
          <FormikRadioSelect
            label={"Looking For: "}
            name={"query"}
            values={[
              { id: 1, value: "flight", label: "Flight" },
              { id: 2, value: "hotel", label: "Hotel" },
              { id: 3, value: "car", label: "Car" },
              { id: 4, value: "package", label: "Package" },
            ]}
            // setVal={(value) => setFieldValue("query", value)}
            width={"80%"}
          />
          <FormikButton
            btnText={btnName ? btnName : "Submit"}
            type="submit"
            bg={"#2582c2"}
          />
          <Typography
            variant="body1"
            color="error"
            style={{ marginTop: "28px", fontSize: "0.75rem" }}
          >
            {error}
          </Typography>
        </Form>
      </Formik>
      <AddQueryConfirmation
        open={showAddQueryConfirmation}
        onClose={() => {
          setShowAddQueryConfirmation(false);
        }}
      />
    </>
  );
};

export default QueryForm;
