import { Grid } from "@mui/material";
import React from "react";
// import SpiritImage from "./../../assets/AirlinesBanner/SPIRIT.jpeg";
// import SpiritLogo from "./../../assets/Airlines/USA Airlines/Spirit.webp";
import FlightImage from "./components/flightImage/FlightImage";
import FlightLogo from "./components/FlightLogo";
import GridSpace from "../footer/components/GridSpace";
import ServicesOffered from "./components/service/ServicesOffered";
import CheapFlights from "./components/cheapFlights/CheapFlights";
import Itinerary from "./components/itinerary/Itinerary";
// import useSpiritItinerary from "./useSpiritItinerary";
import ChooseUs from "../home/chooseUs/ChooseUs";
import useChooseUs from "../home/chooseUs/hooks/useChooseUs";
import CherryPoints from "./components/cherryPoints/CherryPoints";
import WhoAreWe from "./components/whoAreWe/WhoAreWe";
import FAQs from "./components/faqs/FAQs";
// import Footer from "./components/footer/Footer";
import Footer from "../footer/Footer";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";

const AirlinePage = ({ airline }) => {
  // const { list: spiritItineraryList } = useSpiritItinerary();
  const { WhyChooseUs } = useChooseUs();
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {/* <Grid item xs={12} md={12} sm={12} lg={12}>
        <FlightImage
          imgSrc={SpiritImage}
          imgText={"Spirit Airlines"}
          price={"$59"}
        />
      </Grid> */}
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <FlightImage
          imgSrc={airline.banner}
          imgText={airline.name}
          price={airline.price}
        />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      {/* <Grid item xs={12} md={12} sm={12} lg={12}>
        <FlightLogo logo={SpiritLogo} text={"Spirit Airlines Logo "} />
      </Grid> */}
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <FlightLogo logo={airline.logo} text={airline.name + " Logo "} />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <ServicesOffered />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CheapFlights />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Itinerary list={airline.itineraryList} />
        {/* <Itinerary list={spiritItineraryList} /> */}
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <ChooseUs chooseUs={WhyChooseUs} />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CherryPoints />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <FAQs />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <WhoAreWe />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <GridSpace />
        <GridSpace />
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AirlinePage;
