import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import AppIcon from "./../../assets/LogoTransparentBackground.png";
import useNavbar from "./hooks/useNavbar";
import NavbarDrawer from "./component/drawer/NavbarDrawer";
import NavbarAppBar from "./component/appBar/NavbarAppBar";

const Navbar = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    window.scrollTo(0,0);
  };

  const { pages } = useNavbar();

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <NavbarAppBar
            AppIcon={AppIcon}
            isMediumScreen={isMediumScreen}
            isSmallScreen={isSmallScreen}
            pages={pages}
            toggleMenu={toggleMenu}
          />
          <NavbarDrawer
            pages={pages}
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
