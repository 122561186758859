import React from "react";
import { useMediaQuery } from "@mui/material";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

import "./slick.css";
import "./slick-theme.css";

const VacationSliderContent = ({ destination }) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  return (
    <div>
      <Card
        className="project"
        sx={
          isSmallScreen ?
          {
          height: "16em",
          padding: "1em",
          margin: "1.5em",
          backgroundColor: "#2582c2",
          position: "relative",
          overflow: "hidden",
          "&:hover .MuiCardMedia-img": {
            transform: "scale(1.3)",
          },
          "&:hover .MuiCardContent-root": {
            bottom: "0", 
          },
        }: {
          height: "20em",
          padding: "1em",
          margin: "1.5em",
          backgroundColor: "#2582c2",
          position: "relative",
          overflow: "hidden",
          "&:hover .MuiCardMedia-img": {
            transform: "scale(1.3)",
          },
          "&:hover .MuiCardContent-root": {
            bottom: "0.5em", 
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            height: "97%",
            objectFit: "cover",
            transition: "transform 400ms ease-in-out",
          }}
          image={destination.url}
          alt={destination.title}
        />
        <CardContent
          sx={{
            position: "absolute",
            right: "0",
            left: "0",
            bottom: "0",
            textAlign: "left",
            padding: "0.5rem",
            // background:
            //   "linear-gradient(rgba(0,0,0, 0.100), rgba(0,0,0, 0.80))",
            color: "white",
            transition: "all 400ms ease-in-out",
          }}
        >
          <Typography
            variant="h6"
            style={{
              background:
                "linear-gradient(rgba(0,0,0, 0.100), rgba(0,0,0, 0.80))",
              textAlign: "center",
            }}
          >
            {destination.title}
          </Typography>
        </CardContent>        
      </Card>
    </div>
  );
};

export default VacationSliderContent;
