import React, {useState} from "react";
import Navbar from "../navbar/Navbar";
import Main from "../main/Main";
import Footer from "../footer/Footer";
// import DiscountModal from "../discountModal/DiscountModal";
import SignupForm from "../discountModal/SignupForm";
import ScrollToTop from "./../scrollToTop/ScrollToTop";

const Website = () => {
  const [modalOpen, setModalOpen] = useState(true);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Main />
      <Footer />
      {/* <DiscountModal open={modalOpen} handleClose={handleCloseModal} /> */}
      <SignupForm open={modalOpen} handleClose={handleCloseModal} />
    </>
  );
};

export default Website;
