import {
  useMediaQuery,
  Box,
  Grid,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import React from "react";
import Title from "./Title";

const ChooseUs = ({ chooseUs }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1024px)");

  return (
    <Box
      style={{
        width: "100%",
        position: "relative",
        background:
          "linear-gradient(180deg, rgba(237,234,219,10) 30%, rgba(149,216,219,.6) 100%)",
        padding: "1em 0em",
        borderTopLeftRadius: 106,
        borderBottomRightRadius: 106,
      }}
    >
      <Title />
      <div
        style={
          isSmallScreen
            ? {
                position: "absolute",
                backgroundColor: "#8f9d9a",
                width: "90%",
                height: "80%",
                bottom: 0,
                left: 0,
                zIndex: -1,
                clipPath: "polygon(36% 0, 100% 0%, 75% 100%, 0% 100%)",
              }
            : {
                position: "absolute",
                backgroundColor: "#8f9d9a",
                width: "100%",
                height: "80%",
                bottom: 0,
                left: 0,
                zIndex: -1,
                clipPath: "polygon(36% 0, 100% 0%, 75% 100%, 0% 100%)",
              }
        }
      ></div>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {chooseUs.map((why, index) => (
              <Card key={index}
                sx={
                  isSmallScreen
                    ? {
                        backgroundColor: "#ffcc00",
                        borderColor: "#2582c2",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                        color: "#2582c2",
                        height: "23em",
                        borderRadius: 32,
                        width: "35%",
                        margin: ".5em",
                      }
                    : isMediumScreen
                    ? {
                        backgroundColor: "#ffcc00",
                        borderColor: "#2582c2",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                        color: "#2582c2",
                        height: "22em",
                        borderRadius: 32,
                        width: "25%",
                        margin: ".5em",
                      }
                    : {
                        backgroundColor: "#ffcc00",
                        borderColor: "#2582c2",
                        borderWidth: "0.5em",
                        borderStyle: "solid",
                        color: "#2582c2",
                        height: "30em",
                        borderRadius: 32,
                        width: "20%",
                        margin: ".5em",
                      }
                }
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="start"
                  sx={{ height: "100%" }}
                >
                  <Avatar
                    variant="rounded"
                    alt={why.title}
                    src={why.imageUrl}
                    sx={
                      isSmallScreen
                        ? { width: 70, height: 70, marginTop: ".5em" }
                        : isMediumScreen
                        ? { width: 90, height: 90, marginTop: ".8em" }
                        : { width: 120, height: 120, marginTop: "1em" }
                    }
                  />
                  <CardContent
                    style={{
                      height: "70%",
                      width: "100%",
                      padding: "1em 2em",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {isSmallScreen ? (
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        fontWeight={600}
                        fontSize={"12px"}
                      >
                        {why.title}
                      </Typography>
                    ) : isMediumScreen ? (
                      <Typography
                        variant="body2"
                        textAlign="center"
                        fontWeight={600}
                        marginX={"1em"}
                      >
                        {why.title}
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        textAlign="center"
                        fontWeight={600}
                        marginX={"1em"}
                      >
                        {why.title}
                      </Typography>
                    )}

                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "90%",
                      justifyContent: "center",
                    }}>
                      <Divider 
                        sx={{ my: 2, borderColor: "#477068", borderWidth: 2 }}
                      />
                    </div>
                    {isSmallScreen ? (
                      <Typography
                        variant="caption"
                        sx={{
                          padding: "0em .3em",
                          textAlign: "center",
                          fontSize: "12px",
                        }}
                      >
                        {why.description}
                      </Typography>
                    ) : isMediumScreen ? (
                      <Typography
                        variant="caption"
                        sx={{
                          padding: "0em 1em",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                      >
                        {why.description}
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          padding: "0em 1em",
                          textAlign: "center",
                          fontWeight: 600,
                          marginX: ".8em",
                          fontSize: "16",
                        }}
                      >
                        {why.description}
                      </Typography>
                    )}
                  </CardContent>
                </Box>
              </Card>
            ))}
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChooseUs;
