import React from "react";
import { Grid, Typography } from "@mui/material";
import GridSpace from "../footer/components/GridSpace";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";
import useTermsAndConditions from "./useTermsAndConditions";

const TermsAndConditions = () => {
  const { tnc } = useTermsAndConditions();
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div>
            <Typography variant="h1" fontWeight={600} textAlign={"center"}>
              Terms And Conditions
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "70%" }}>
                <Typography variant="body1" textAlign={"justify"}>
                  {tnc.map((paragraph, index) =>
                    typeof paragraph === "string" ? (
                      <>
                        {paragraph}
                        <br />
                      </>
                    ) : Array.isArray(paragraph) ? (
                      <>
                        <ul>
                          {paragraph.map((obj, id) =>
                            typeof obj === "object" && obj !== null ? (
                              <li key={id}>
                                <b>{obj.topic}</b>
                                <br />
                                <ul>
                                  {Array.isArray(obj.desc) ? (
                                    obj.desc.map((descs, i) => (
                                      <li key={i}>{descs}</li>
                                    ))
                                  ) : (
                                    <>
                                      <li>{obj.desc.topic}</li>
                                      <ul>
                                        {Array.isArray(obj.desc.desc) &&
                                          obj.desc.desc.map((descr, ind) => (
                                            <li key={ind}>{descr}</li>
                                          ))}
                                      </ul>
                                    </>
                                  )}
                                </ul>
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default TermsAndConditions;
