import Flights from "./../../../../assets/Home/ourServices/flights.jpg";
import Hotels from "./../../../../assets/Home/ourServices/hotels.jpg";
import Packages from "./../../../../assets/Home/ourServices/packages.png";

const useServices = () => {
  return {
    services: [
        {
        imageUrl: Flights,
        title: "Flights",
        descriptionPart1: "Unlock Affordable Adventures: ",
        descriptionPart2: "Discover the Best Airfares to Your Dream Destinations!"
    },{
        imageUrl: Hotels,
        title: "Hotels",
        descriptionPart1: "Discover Your Perfect Stay: ",
        descriptionPart2: "Book the Best Hotels at Unbeatable Prices!",
    },{
        imageUrl: Packages,
        title: "Packages",
        descriptionPart1: "Craft Your Ultimate Escape: ",
        descriptionPart2: "Explore Exclusive Travel Packages Tailored Just for You!",
    },
]
  }
}

export default useServices;