import React from "react";
import { Button } from "@mui/material";

const SliderButton = ({ icon, onClickFn }) => {
  return (
    <Button
      style={{
        margin: ".5em",
      }}
      onClick={() => onClickFn()}
    >
      {icon}
    </Button>
  );
};

export default SliderButton;
