import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  box: {
    backgroundColor: "#282929",
    color: "white",
    marginTop: "2em",
    minWidth: "100%",
    maxWidth: "100%",
  },
  containerBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    padding: "1em 1em",
  },
}));
