import Delta from "./USA Airlines/Delta.webp";
import Envoy from "./USA Airlines/Envoy.webp";
import Spirit from "./USA Airlines/Spirit.webp";
import Alaska from "./USA Airlines/Alaska.webp";
// import AirCanada from "./USA Airlines/Air_Canada.webp";
import UnitedAirlines from "./USA Airlines/United-Airlines.webp";
import Southwest from "./USA Airlines/Southwest-Airlines.webp";
import Hawaiian from "./USA Airlines/Hawaiian_Airlines.webp";
import AllegiantAir from "./USA Airlines/Allegiant-Air.webp";
import Frontier from "./USA Airlines/frontier-airlines.webp";
import JetBlue from "./USA Airlines/JetBlue.webp";
import CapeAir from "./USA Airlines/CapeAir.webp";
import AmericanAirlines from "./USA Airlines/American Airlines.webp";

const usaAirlines = () => {
  return {
    airlines: [
      {
        title: "Spirit",
        url: Spirit,
      },
      {
        title: "Allegiant Air",
        url: AllegiantAir,
      },
      {
        title: "Delta",
        url: Delta,
      },
      {
        title: "Cape Air",
        url: CapeAir,
      },
      {
        title: "American Airlines",
        url: AmericanAirlines,
      },
      {
        title: "Envoy",
        url: Envoy,
      },
      {
        title: "Jet Blue Airways",
        url: JetBlue,
      },
      {
        title: "Alaska",
        url: Alaska,
      },
      {
        title: "United Airlines",
        url: UnitedAirlines,
      },
      {
        title: "Southwest Airlines",
        url: Southwest,
      },
      {
        title: "Frontier Airlines",
        url: Frontier,
      },
      // {
      //   title: "Air Canada",
      //   url: AirCanada,
      // },
      // {
      //   title: "Emirates",
      //   url: Emirate,
      // },
      {
        title: "Hawaiian Airlines",
        url: Hawaiian,
      },
      // {
      //   title: "King Fisher",
      //   url: KingFisher,
      // },
      // {
      //   title: "SriLankan Airlines",
      //   url: SriLankanAirlines,
      // },
      // {
      //   title: "Air Arabia",
      //   url: AirArabia,
      // },
    ],
  };
};

export default usaAirlines;
