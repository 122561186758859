import React from "react";
import CallTollFreeNo from "../home/callTollFree/CallTollFreeNo";
import GridSpace from "../footer/components/GridSpace";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import DestinationsList from "./DestinationsList";
import TopVacationDestinations from "../../assets/vacationDestinations/TopVacationDestinations";

const Destinations = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { destinations } = TopVacationDestinations();
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bg={"#2582c2"} bgBtn={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Typography
            variant={isSmallScreen ? "h4" : "h1"}
            fontWeight={600}
            textAlign={"center"}
          >
            Top Vacation Destinations
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DestinationsList list={destinations} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CallTollFreeNo bgBtn={"#2582c2"} bg={"#ffcc00"} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GridSpace />
          <GridSpace />
        </Grid>
      </Grid>
    </>
  );
};

export default Destinations;
