import axios from "axios";
import { urls } from "../config/env-config";

const ApiService = () => {
    const promiseWithErrorHandling = (promise) => {
        return promise.catch((err) => {
          if (err.response && err.response.status === 500) {
            console.error("error", err);
          } else {
            console.error("error", err);
            throw err;
          }
        });
      };
  return {
    postPublic: async (path, payload) => {
        return promiseWithErrorHandling(
          axios.post(`${urls.service}/${path}`, payload)
        );
      },
  }
}

export default ApiService;