import React from "react";
import { useField } from "formik";
import { TextField, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

const FormikTextField = (props) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  const [field, meta] = useField(props.name);

  const { value, onChange, onBlur } = field;
  const { error, touched } = meta;

  return (
    <TextField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={touched && Boolean(error)}
      helperText={touched ? error : ""}
      InputLabelProps={{
        style: {
          color: "black",
          fontSize: isSmallScreen || isMediumScreen ? "18px" : "20px",
          fontFamily: "ITC Bauhaus font",
          // fontFamily: "serif",
          fontWeight: isSmallScreen || isMediumScreen ? 600 : 700,
        },
      }}
      InputProps={{
        style: {
          height: isSmallScreen || isMediumScreen ? "20" : "30",
          fontSize: isSmallScreen || isMediumScreen ? "18px" : "20px",
        },
      }}
      FormHelperTextProps={{
        style: {
          width: "max-content",
        },
      }}
      {...props}
    />
  );
};

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormikTextField;
