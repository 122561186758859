import React from "react";
import {
  Modal,
  Box,
  IconButton,
  useMediaQuery,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QueryForm from "../home/requirement/queryForm/QueryForm";
import AvailDiscountImg from "./../../assets/Discount/AvailDiscount.png";

const SignupForm = ({ open, handleClose }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1024px)");
  const style = {
    position: "relative",
    // position: "absolute",
    top: "50%",
    left: isSmallScreen ? "50%" : isMediumScreen ? "32%" : "35%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 230 : isMediumScreen ? 250 :  400,
    // bgcolor: "background.paper",
    border: "5px solid #000",
    borderRadius: 16,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(180deg, rgba(237,234,219,10) 0%, rgba(149,216,219,.9) 100%)",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 3,
            right: 35,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant={isSmallScreen ? "body1" : "h5"}
          fontWeight={600}
          maxWidth={isMediumScreen ? "70%" : "60%"}
        >
          Sign Up And Avail Discount
        </Typography>
        <QueryForm
          offer={"Discount upto 70% "}
          code={"Use Code: WOB24SIGNUP"}
          btnName={"Sign Up"}
        />
        {isSmallScreen ? (
          <></>
        ) : (
          <img
            src={AvailDiscountImg}
            alt="avail discount."
            style={{
              borderRadius: "36px",
              position: "absolute",
              // top: "50%",
              right: "-105%",
              width: "110%",
            }}
          />
        )}
        
      </Box>
    </Modal>
  );
};

export default SignupForm;
