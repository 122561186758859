// import indianAirlines from "./indianAirlines";
import usaAirlines from "./usaAirlines";

const allAirlines = () => {
  const { airlines: usaAirlineList } = usaAirlines();
  // const { airlines: indianAirlineList } = indianAirlines();
  return {
    airlines: [...usaAirlineList],
    // airlines: [...indianAirlineList, ...usaAirlineList],
  };
};

export default allAirlines;
