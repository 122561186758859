import React from "react";
import Title from "./Title";
import { Typography } from "@mui/material";

const Component = ({ title, contents }) => {
  return (
    <div>
      <Title title={title} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "2em",
        }}
      >
        <div style={{ width: "60%" }}>
          <Typography variant="body1" textAlign={"justify"}>
            {contents.map((content) => {
              return (
                <>
                {content}
                <br /> <br />
                </>
            );
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Component;
