import { Grid } from '@mui/material';
import React from 'react';
import FaqIcon from './FaqIcon';
import FaqContents from './FaqContents';

const FAQs = () => {
  return (
    <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    }}>
    <Grid container item xs={12} sm={12} lg={9} md={9}>
        <FaqIcon />
        <FaqContents />
    </Grid>
    </div>
  )
}

export default FAQs;