import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LinkButton = ({ url, icon, text, fontFam }) => {
  const navigate = useNavigate();
  const handleOpenPage = () => {
    if (
      url === "/" ||
      url === "/about-us" ||
      url === "/contact-us" ||
      url === "/privacy-policy" ||
      url === "/terms-and-conditions" ||
      url === "/refund-policy" || 
      url === "/flights"
    ) {
      navigate(url);
      window.scrollTo(0,0);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleOpenEmail = () => {
    window.location.href = `mailto:${url}`;
  };

  return (
    <Button
      sx={{ color: "white", textTransform: "none" }}
      onClick={text === "Email" ? handleOpenEmail : handleOpenPage}
      disabled={url === ""}
    >
      {icon}
      <Typography
        variant="body1"
        style={{
          color: "white",
          fontFamily: fontFam ? fontFam : "ITC Bauhaus font",
          textAlign: "left",
          padding: "0 5px",
        }}
      >
        {text === "Email" ? "" : text}
      </Typography>
    </Button>
  );
};

export default LinkButton;
