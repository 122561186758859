import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const WhoAreWeCard = ({ icon, title, description }) => {
  return (
    <Grid item lg={4} md={5} sm={5} xs={10}>
      <Card
        sx={{
          width: "100%",
          height: "260px",
          backgroundColor: "transparent",
          padding: "1em 0em",
          border: "3px solid #2582c2",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            color: "black",
            height: "100%",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#2582c2",
              fontWeight: 600,
              width: "100%",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" style={{ textAlign: "center" }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default WhoAreWeCard;
