import React from "react";
import { useMediaQuery, Box, Typography, Grid } from "@mui/material";

const Title = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:800px)");
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item lg={6} md={6} sm={8} xs={10}>
        {isSmallScreen ? (
          <Typography
            variant="h4"
            textAlign={"center"}
            fontWeight={600}
            color={"#2582c2"}
          >
            Why <span style={{
                color: "#ffcc00"
              }} > Choose </span>Us ?
          </Typography>
        ) : isMediumScreen ? (
          <Typography
            variant="h3"
            textAlign={"center"}
            fontWeight={700}
            color={"#2582c2"}
            >
              Why <span style={{
                color: "#ffcc00"
              }} > Choose </span>Us ?
          </Typography>
        ) : (
          <Typography
            variant="h2"
            textAlign={"center"}
            fontWeight={700}
            color={"#2582c2"}
            >
              Why <span style={{
                color: "#ffcc00"
              }} > Choose </span>Us ?
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Title;
